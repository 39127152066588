<template>
    <Head title="Vendor Invoice Gathering - Batch Upload" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('gathered-vendor-invoice-files.index')" class="">Gathering</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>CSV Upload</span>
        </nav>
    </Teleport>

    <div class="w-full mb-8 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-4">
        <div class="flex items-center space-x-2">
            <div class="flex-shrink-0">
                <div class="relative">
                    <span class="inline-flex items-center justify-center h-14 w-14 mr-2">
                        <icon name="file-invoice" class="w-8 h-8 fill-current" />
                    </span>
                </div>
            </div>

            <div>
                <h1 class="text-2xl font-bold text-gray-900">
                    Batch Invoice Upload
                </h1>
            </div>
        </div>
        <div>
            <div class="flex items-center justify-start w-full lg:justify-end">
                <div>
                    <a :href="$route('gathered-vendor-invoice-files.download-helper-file')" class="mx-1 btn btn-gray">
                        <icon name="download-arrow" class="fill-current h-4 w-4 mr-2 lg:mx-1" />
                        Helper
                    </a>
                </div>

                <div>
                    <a :href="$route('gathered-vendor-invoice-files.download-template-file')" class="mx-1 btn btn-gray">
                        <icon name="download-arrow" class="fill-current h-4 w-4 mr-2 lg:mx-1" />
                        Template
                    </a>
                </div>

                <inertia-link :href="$route('gathered-vendor-invoice-files.index')" class="p-2 btn btn-gray m-2">
                    <span v-if="!batchSubmitted">Cancel</span>
                    <span v-else>Return to Gather</span>
                </inertia-link>
            </div>
        </div>
    </div>

    <div class="rounded-md bg-blue-50 p-4 mb-8" v-if="batchSubmitted && batchFinishedWithPartialErrors">
        <div class="flex">
            <div class="flex-shrink-0">
                <icon name="info-circle" class="h-6 w-6 text-blue-400 fill-current" />
            </div>

            <div class="ml-3 flex-1 md:flex md:justify-between">
                <p class="text-blue-700">The batch was uploaded, but some files failed. Please review any failed files and reupload if necessary.</p>
            </div>
        </div>
    </div>

    <div class="grid grid-cols-1 gap-12 lg:grid-cols-2">
        <!--  LEFT SIDE  -->
        <div class="w-full order-2 lg:order-1">
            <div class="mb-6">
                <text-input class="col-span-2" placeholder="Batch Name" :disabled="batchSubmitted" v-model="form.batch_name" label="Batch Name For This Upload" :errors="batchNameErrors" />
            </div>

            <div v-if="!batchSubmitted"
                 class="mt-2 flex justify-center rounded-lg border-2 border-dashed px-6 py-16"
                 :class="{'border-gray-900/50 bg-gray-100': isDragging, 'border-gray-500/25': !isDragging}"
                 @dragover.prevent="isDragging = true"
                 @dragleave="isDragging = false"
                 @drop.prevent="filesDropped"
            >
                <div class="text-center">
                    <icon name="file-pdf" class="mx-auto h-12 w-12 text-gray-300 fill-current" />
                    <p class="mt-4 leading-5 text-gray-600">Drag and drop CSV here</p>
                    <div class="mt-1 flex justify-center text-sm leading-6 text-gray-600">
                        <label for="ocrDocuments" class="relative cursor-pointer rounded-md bg-transparent font-semibold text-d-orange-600 focus-within:outline-none focus-within:ring-2 focus-within:ring-d-orange-600 focus-within:ring-offset-2 hover:text-d-orange-500">
                            <span>or select them manually</span>
                            <input type="file" multiple id="ocrDocuments" name="ocrDocuments" class="sr-only" ref="fileinput" accept=".csv" @change="onChange">
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="rounded-md bg-green-50 p-4 my-6" v-if="batchSubmitted && batchFinishedWithNoErrors">
        <div class="flex">
            <div class="flex-shrink-0">
                <icon name="check" class="h-5 w-5 text-green-400 fill-current" />
            </div>

            <div class="ml-3">
                <p class="font-medium text-green-800">Successfully uploaded batch</p>
                <p class="text-sm text-green-800" v-if="$page.props.permissions.extractVendorInvoices">
                    Please wait while invoices are extracted as this can take several minutes.
                </p>
            </div>
        </div>
    </div>

    <div class="mt-8 flow-root">
        <div class="w-full flex lg:justify-end">
            <loading-button
                v-if="hasAddedFiles && !batchSubmitted && !batchFinishedWithNoErrors"
                :loading="batchSubmitted"
                class="btn btn-orange text-white mb-5 mx-8 w-full justify-center text-base py-3 lg:w-auto lg:text-sm lg:px-3 lg:py-2 lg:mx-0"
                @click="submitBatch"
            >
                Submit Batch
            </loading-button>
        </div>

        <div class="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div class="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <table class="min-w-full divide-y divide-gray-300">
                    <thead class="text-left">
                        <tr>
                            <th scope="col" class="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0">File name</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">File size</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">File type</th>
                            <th scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">Validation</th>
                            <th scope="col" class="relative py-3.5 pl-3 pr-4 sm:pr-0">
                                <span class="sr-only">Action</span>
                            </th>
                        </tr>
                    </thead>
                    <tbody class="divide-y divide-gray-200">
                        <tr v-if="!hasAddedFiles">
                            <td colspan="5">
                                <div class="py-12 px-3 text-center">
                                    <h3 class="mt-3 text-sm font-semibold text-gray-900">No Files Queued</h3>
                                    <p class="mt-1 text-sm text-gray-500">Adding files above will show them queued here</p>
                                </div>
                            </td>
                        </tr>
                        <tr v-for="file in failed" :key="file.id" class="bg-red-100">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900">{{ file.document.name }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ toHumanSize(file.document.size) }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ file.document.type }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-red-600 font-medium">{{ file.errors?.document?.join(', ') }}</td>
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm">
                                <button type="button" class="btn btn-gray text-xs py-1 px-2 float-right" @click="retryUpload(file)" title="Retry Upload">Retry Upload</button>
                            </td>
                        </tr>
                        <tr v-for="file in queued" :key="file.id" :class="{'bg-red-100': file.invalid()}">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ file.document.name }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ toHumanSize(file.document.size) }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ file.document.type }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-red-600">{{ file.validation_error() }}</td>
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm sm:pr-0">
                                <button type="button" class="btn btn-blue px-2.5 py-1.5 text-sm lg:px-2 lg:py-1 lg:text-xs" @click="removeQueuedFile(file)" title="Remove Upload">Remove From Queue</button>
                            </td>
                        </tr>
                        <tr v-for="file in uploading" :key="file.id">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ file.document.name }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ toHumanSize(file.document.size) }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ file.document.type }}</td>
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4"></td>
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4"></td>
                        </tr>
                        <tr v-for="file in uploaded" :key="file.id">
                            <td class="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-0">{{ file.document.name }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ toHumanSize(file.document.size) }}</td>
                            <td class="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{{ file.document.type }}</td>
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4"></td>
                            <td class="relative whitespace-nowrap py-4 pl-3 pr-4"></td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>

    <hr class="my-6 border-gray-300">

    <div v-if="currentBulkImports?.data?.length">
        <div class="my-6">
            <h2 class="text-xl font-semibold text-gray-900">Recent Bulk Imports</h2>
            (Showing <span class="font-semibold text-gray-900">{{ currentBulkImports.data.length }}</span> out of <span class="font-semibold text-gray-900">{{ currentBulkImports.total }}</span>)
        </div>

        <div class="overflow-x-auto">
            <table class="table table-condensed">
                <thead>
                    <tr>
                        <th class="font-semibold">Timestamp</th>
                        <th class="font-semibold">File Name</th>
                        <th class="font-semibold">Status</th>
                        <th class="font-semibold">User</th>
                        <th class="font-semibold">Errors</th>
                        <th class="font-semibold">Error File</th>
                        <th class="font-semibold"></th>
                    </tr>
                </thead>

                <tbody>
                    <tr v-for="bulkImport in currentBulkImports.data" :key="bulkImport.id">
                        <td>{{ $filters.format_date_time(bulkImport.created_at) }}</td>
                        <td><a class="link" :href="$route('tenant-settings.bulk-imports.download', bulkImport.id)" target="_blank">{{ bulkImport.file_name }}</a></td>
                        <td>{{ bulkImport.status }}</td>
                        <td>{{ bulkImport.user ? bulkImport.user.name : "Unknown" }}</td>
                        <td>
                            <span v-if="bulkImport.status !== 'Queued'">{{ bulkImport.error_count }}</span>
                        </td>

                        <td>
                            <a class="link" v-if="bulkImport.error_count > 0" :href="$route('tenant-settings.bulk-import-errors.show', bulkImport.id)" target="_blank">(Download Errors)</a>
                        </td>

                        <td></td>
                    </tr>
                </tbody>
            </table>
        </div>

        <pagination :links="currentBulkImports.links" />
    </div>
</template>

<script>
    import Pagination from '@/Shared/Pagination.vue';
    import Icon from '@/Shared/Icon.vue';
    import Modal from '@/Shared/Modal.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import CopyToClipboardButton from '@/Shared/CopyToClipboardButton.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import axios from 'axios';
    import { Head, router } from '@inertiajs/vue3';

    export default {
        components: {
            Pagination,
            Icon,
            Modal,
            LoadingButton,
            TextInput,
            SelectInput,
            TextareaInput,
            DateInput,
            CopyToClipboardButton,
            SearchInput,
            Head,
        },

        props: {
            filters: Object,

            errors: {
                type: Object,
                default: () => ({}),
            },

            batchName: {
                type: String,
            },

            currentBulkImports: {
                type: Object,
            },

            vendorInvoiceDataExtractionEmail: {
                type: String
            }
        },

        data() {
            return {
                form: {
                    batch_name: this.batchName
                },
                billingConfig: this.$page.props.tenant.tenantBillingConfiguration,
                maxSimultaneousUploads: 1,
                interval: false,
                queued: [],
                uploading: [],
                uploaded: [],
                failed: [],
                isDragging: false,
                index: 1,
                warnUser: null,
                batchSubmitted: false,
                mounted: false,
            }
        },

        mounted() {
            Echo.join(`gathered-vendor-invoice-files.create-csv-upload.${this.$page.props.authUser.current_tenant_id}`)
                .listen('.App\\Events\\GatheredVendorInvoiceFile\\CreateFromCSVUploadHasFinished', (e) => {
                    if (e.error_count > 0) {
                        this.$toast.error(e.message);
                    } else {
                        this.$toast.success(e.message);
                    }

                    this.$inertia.reload({only: ['currentBulkImports']});
                });

            this.mounted = true;
        },

        unmounted() {
            Echo.leave(`gathered-vendor-invoice-files.create-csv-upload.${this.$page.props.authUser.current_tenant_id}`);
        },

        methods: {
            submitBatch() {
                this.batchSubmitted = true

                if (this.interval) {
                    clearInterval(this.interval);
                }

                this.interval = setInterval(function() {
                    while (this.batchSubmitted && this.queued.length && this.uploading.length < this.maxSimultaneousUploads) {
                        this.uploadFile(this.queued.shift())
                    }

                    if (this.batchFinishedWithNoErrors) {
                        clearInterval(this.interval);
                    }
                }.bind(this), 1000);
            },

            onChange() {
                let filelist = [...this.$refs.fileinput.files];

                filelist.forEach((file) => {
                    if (this.checkForDuplicates(file)) {

                        this.queued.push({
                            id: this.index++,
                            document: file,
                            validation_error: () => this.validateFile(file),
                            invalid: () => this.validateFile(file) !== '',
                            errors: null,
                        })
                    }
                });
            },

            checkForDuplicates(file) {
                let found = true;
                this.queued.forEach((existing) => {

                    if (existing.document.name && existing.document.name === file.name) {
                        found = false;
                        this.$toast.error('A file with that name has already been added to the queue!');
                    }
                })
                return found;
            },

            removeQueuedFile(file) {
                let index = this.queued.indexOf(file);

                if (index >= 0) {
                    this.queued.splice(index, 1);
                }
            },

            filesDropped(event) {
                this.isDragging = false
                this.$refs.fileinput.files = event.dataTransfer.files;
                this.onChange();
            },

            uploadFile(file) {
                this.uploading.push(file);

                let formData = new FormData();
                formData.append('file_id', file.id);
                formData.append('document', file.document);
                formData.append('batch_name', this.form.batch_name);

                axios.post(this.$route('gathered-vendor-invoice-files.store-csv-upload'), formData)
                    .then(res => {
                        let index = this.uploading.indexOf(file);
                        this.uploading.splice(index, 1);
                        file.gathered_vendor_invoice_file_id = res.data.gathered_vendor_invoice_file_id;
                        this.uploaded.push(file);
                    })
                    .catch(err => {
                        file.errors = err.response.data.errors;
                        this.failed.push(file);
                        let index = this.uploading.indexOf(file)
                        this.uploading.splice(index, 1);
                    })
            },

            retryUpload(file) {
                let failedIndex = this.failed.indexOf(file);
                this.failed.splice(failedIndex, 1);

                this.uploadFile(file);
            },

            toHumanSize(fileSize) {
                let megabytes = Math.round(fileSize/(1024*1024) * 100) / 100
                let kilobytes = Math.round(fileSize/(1024) * 100) / 100

                if (megabytes > 1) return megabytes + 'MB';
                return kilobytes + 'KB';
            },

            validateFile(file) {
                if (file.size >= 20971520) {
                    return 'File size is greater than 20MB';
                }

                if (file.type.search('csv') === -1) {
                    return 'File is not a csv file';
                }

                if (file.name !== 'gathered_vendor_invoice_files.csv') {
                    return 'File name must be gathered_vendor_invoice_files.csv';
                }

                return '';
            }
        },

        computed: {
            hasAddedFiles() {
                return this.queued.length > 0 || this.uploading.length > 0 || this.uploaded.length > 0 || this.failed.length > 0;
            },

            batchFinishedWithNoErrors() {
                return this.queued.length === 0 && this.uploading.length === 0 && this.uploaded.length > 0 && this.failed.length === 0;
            },

            batchFinishedWithPartialErrors() {
                return this.queued.length === 0 && this.uploading.length === 0 && this.uploaded.length > 0 && this.failed.length > 0;
            },

            batchNameErrors() {
                if (!this.form.batch_name.match(/^[0-9a-zA-Z\-]+$/)) {
                    return 'Batch name can only contain letters, numbers and dashes';
                }

                return '';
            },
        }
    }
</script>
