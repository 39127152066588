<template>
    <Head title="Cumulative Portfolio Impact Summaries" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">
                Home
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('compactor-monitors.index')" class="breadcrumb-link">
                Compactor Monitors
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>
                Reports
            </span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>
                Cumulative Portfolio Impact Summaries
            </span>
        </nav>
    </Teleport>

	<div class="max-w-7xl mx-auto 3xl:max-w-screen-2xl my-0 4xl:max-w-none">
		<div class="divide-y md:divide-none">
			<CompactorMonitorIndexHeader />
			<SubNav class="pt-8 md:pt-0 md:mt-8" current-tab="cumulative-monitor-report" :tabs="subNavTabs()" />
		</div>
		<div class="mx-2 md:mx-3 lg:mx-4">
			<div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2 my-1 items-center">
				<div class="text-3xl mt-auto flex">
					Cumulative Monitor Impact
					<tooltip-icon class="mt-1 ml-2" tooltip="Details the net impact of Pioneer monitors compared to the baseline data"/>
				</div>
				<div class="print:hidden lg:grid md:grid-cols-4 lg:grid-cols-5 inline-flex col-start-1 col-span-2 sm:col-span-1 md:col-start-2 justify-center mr-4 md:mr-0 justify-self-end mt-auto">
					<button type="button" class="lg:col-span-2 mt-6 mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white md:font-semibold items-center border border-gray-400  rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.reportFilterSlideOver.show()">
	                    <span class="inline-flex px-3 py-2">
	                        <icon name="filter" class="w-6 h-6 md:w-5 md:h-5 text-gray-400 fill-current" />
	                        <span class="hidden md:block ml-2">Filters</span>
	                    </span>
						<span class="relative px-3 py-2  w-full items-center bg-d-accent-100  md:font-semibold text-d-accent-900 rounded-r-md">
	                        {{ filtersInUse }}
	                    </span>
					</button>

					<inertia-link :href="$route('compactor-monitors.reports.cumulative-portfolio-impact-summaries', {remember: 'forget'})" class="col-span-1 btn btn-gray mt-6 mr-2 text-md text-gray-800 justify-center">Clear</inertia-link>

					<loading-button :loading="state === 'exporting'" class="hidden md:block col-span-2 mt-6 btn btn-gray font-semibold justify-center w-auto" @click="exportData">
						Export to CSV
					</loading-button>
				</div>
				<div :key="redrawCharts" class="block print:hidden md:col-span-2 mb-5">
					<div id="chart-container" class="grid md:grid-cols-2 lg:grid-cols-3 flex shadow py-5 rounded-lg">
						<div class="px-6 print:px-0 border-r print:my-0">
							<compactor-monitor-reporting-dashboard-bar-chart
								:series="getHaulCostSeries()"
								:metric-data="reportSummary.delta.haul_cost_sum_money?.amount"
								:delta-percentage="$filters.roundWithPrecision(reportSummary.delta?.haul_cost_sum_pct, 0)"
								trend-prefix="$"
								trend-title="Hauling Cost"
								:is-inverse-trend="true"
								container-name="haul-cost-chart"
								class="col-span-1 print:mx-0"
								ref="haul-cost-chart"/>
						</div>
						<div class="px-6 print:px-0 border-r print:my-0">
							<compactor-monitor-reporting-dashboard-bar-chart
								:series="getPickupsRecordedSeries()"
								:metric-data="$filters.roundWithPrecision(reportSummary.delta?.pickups_recorded_sum, 2)"
								:delta-percentage="$filters.roundWithPrecision(reportSummary.delta?.pickups_recorded_sum_pct, 0)"
								container-name="pickups-recorded-chart"
								trend-suffix="pickups"
								trend-title="Pickups"
								:is-inverse-trend="true"
								class="col-span-1 print:mx-0 print:w-full"
								ref="pickups-recorded-chart"/>
						</div>
						<div class="px-6 print:px-0 border-r print:my-0">
							<compactor-monitor-reporting-dashboard-bar-chart
								:series="getGHGEmissionsSeries()"
								:metric-data="$filters.roundWithPrecision(reportSummary.delta?.ghg_emissions_sum, 2)"
								:delta-percentage="$filters.roundWithPrecision(reportSummary.delta?.ghg_emissions_sum_pct, 0)"
								trend-title="GHG Emissions"
								:trend-suffix="$page.props.small_units"
								:is-inverse-trend="true"
								container-name="ghg-emissions-chart"
								class="col-span-1 print:mx-0 print:w-full"
								ref="ghg-emissions-chart"/>
						</div>
					</div>
				</div>

			</div>

			<div class="shadow rounded p-5">
				<div class="relative w-full overflow-x-auto p-5">
					<table class="table table-condensed overflow-x-scroll  p-4" ref="table">
						<thead class="font-semibold">
                            <tr>
                                <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Client Company</th>
                                <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Location</th>
                                <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Pickup Management <br>Start Date</th>
                                <sortable-table-header field="haul_cost_avoided" :filters="filters" route-name="compactor-monitors.reports.cumulative-portfolio-impact-summaries" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Haul Cost <br>Avoided</sortable-table-header>
                                <sortable-table-header field="pickups_avoided" :filters="filters" route-name="compactor-monitors.reports.cumulative-portfolio-impact-summaries" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Pickups <br>Avoided</sortable-table-header>
                                <sortable-table-header field="ghg_emissions_avoided" :filters="filters" route-name="compactor-monitors.reports.cumulative-portfolio-impact-summaries" scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">GHG Emissions <br>Avoided</sortable-table-header>
                            </tr>
						</thead>

						<tbody>
                            <tr v-for="record in reportData.data">
                                <td class="!border-gray-200">
                                    <inertia-link :href="$route('client-companies.show', record.client_company_id)" class="link">
                                        {{ record.client_company_name }}
                                    </inertia-link>
                                </td>
                                <td class="!border-gray-200">
                                    <inertia-link :href="$route('locations.show', record.location_id)" class="link">
                                        {{ record.location_name }}
                                    </inertia-link>
                                </td>
                                <td class="!border-gray-200">
                                    {{ $filters.format_date(record.pickup_management_start_date) }}
                                </td>
                                <td class="!border-gray-200">
                                    <span
                                        :class="{
                                        'bg-red-200 rounded rounded-full p-2': parseAndSign(record.haul_cost_avoided?.amount) === -1,
                                        'bg-green-200 rounded rounded-full p-2': parseAndSign(record.haul_cost_avoided?.amount) === 1}"
                                    >
                                        {{ !record.haul_cost_avoided || parseAndSign(record.haul_cost_avoided.amount) === 0 ? '' : $filters.format_money(record.haul_cost_avoided) }}</span>
                                </td>
                                <td class="!border-gray-200">{{ $filters.roundWithPrecision(record.pickups_avoided, 2)?.toLocaleString() }}</td>
                                <td class="!border-gray-200">{{ $filters.roundWithPrecision(record.ghg_emissions_avoided, 2)?.toLocaleString() }}</td>
                            </tr>
						</tbody>
					</table>
				</div>
			</div>

			<pagination-2 v-if="reportData.data.length" :list="reportData" />

			<div v-if="!reportData.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
				<icon name="dumpster" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
				<span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Report Data</span>
			</div>
		</div>
	</div>

	<slide-over ref="reportFilterSlideOver">
		<template #header="{close}">
			<div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
				<div class="flex items-start justify-between">
					<h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
					<div class="ml-3 flex h-7 items-center">
						<button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
							<span class="sr-only">Close panel</span>
							<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
								<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>

				</div>
			</div>
		</template>

		<template #body>
			<form @submit.prevent id="cumulative-portfolio-impact-summaries-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
				<div class="col-span-6 sm:col-span-1">
                    <Combobox as="div" class="col-span-6 sm:col-span-1 mb-5" v-model="form.client_companies" multiple @update:model-value="filterSelectedLocations">
                        <ComboboxLabel class="form-label" for="locations">Client Companies</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="client-companies" name="client-companies" class="form-input" @focus="$event.target.select()" @change="clientCompaniesComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" autocomplete="off"/>

                            <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="sortedClientCompanyOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="clientCompany in sortedClientCompanyOptions" :key="clientCompany.id" :value="clientCompany.id" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ clientCompany.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                        <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>

                    <Combobox as="div" class="col-span-6 sm:col-span-1 mb-5" v-model="form.locations" multiple>
                        <ComboboxLabel class="form-label" for="locations">Locations</ComboboxLabel>

                        <div class="relative">
                            <ComboboxInput id="locations" name="locations" class="form-input" @focus="$event.target.select()" @change="locationsComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" autocomplete="off"/>

                            <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                <!-- Heroicon name: solid/selector -->
                                <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                    <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                </svg>
                            </ComboboxButton>

                            <ComboboxOptions v-if="sortedLocationOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                <ComboboxOption v-for="location in sortedLocationOptions" :key="location.id" :value="location.id" as="template" v-slot="{ active, selected }">
                                    <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ location.name }}
                                        </span>

                                        <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                        <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                    </li>
                                </ComboboxOption>
                            </ComboboxOptions>
                        </div>
                    </Combobox>

					<select-input-2 id="start_date" name="start_date" :options="months" class="col-span-6 sm:col-span-1 mb-5" v-model="form.start_date" label="Start Date">
						<template #option-display="{option, selected}">
							<div :class="{'font-semibold': selected}" class="block">
								<div>{{ option }}</div>
							</div>
							<span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                            </span>
						</template>
					</select-input-2>

                    <select-input-2 id="end_date" name="end_date" :options="months" class="col-span-6 sm:col-span-1" v-model="form.end_date" label="End Date">
                        <template #option-display="{option, selected}">
                            <div :class="{'font-semibold': selected}" class="block">
                                <div>{{ option }}</div>
                            </div>
                            <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                            </span>
                        </template>
                    </select-input-2>
				</div>
			</form>
		</template>
	</slide-over>

</template>

<style>
.form-input-with-buttons input::-webkit-outer-spin-button{
	display: block;
}

.form-input-with-buttons input::-webkit-inner-spin-button {
	display: block;
}
</style>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput2 from "../../../Shared/SelectInput2.vue";
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SlideOver from "@/Shared/SlideOver.vue";
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import {throttle} from 'lodash-es';
    import {Head, router} from '@inertiajs/vue3';
    import {computed, inject, onMounted, reactive, ref, watch} from 'vue';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';
    import {filters as $filters} from "../../../Shared/Utils/Filters";
    import CompactorMonitorReportingDashboardBarChart from "../../../Shared/Reports/CompactorMonitors/Dashboard/CompactorMonitorReportingDashboardBarChart.vue";
    import CompactorMonitorIndexHeader from "../Index/CompactorMonitorIndexHeader.vue";
    import SubNav from "../Index/SubNav.vue";
    import TooltipIcon from "../../../Shared/TooltipIcon.vue";
    import Pagination2 from "../../../Shared/Pagination2.vue";

    /**
     * Props
     */
    const props = defineProps({
        reportData: {
            type: Object,
            required: true
        },

        allReportData: {
            type: Object,
            required: true
        },

        months: {
            type: Array,
            required: true
        },

        filters: Object,

        reportSummary: {
            type: Object,
            required: true
        },

        clientCompanies: {
            type: Array,
            required: true
        },

        locations: {
            type: Array,
            required: true
        }
    });

    const route = inject('route');
    const toast = inject('toast');

    /**
     * State
     */
    const form = reactive({
        client_companies: props.filters.client_companies ?? [],
        locations: props.filters.locations ?? [],
        start_date: props.filters.start_date,
        end_date: props.filters.end_date,
        sort_by: props.filters.sort_by,
        sort_direction: props.filters.sort_direction,
    });

    const saving = ref('passive');
    const state = ref('passive');
    const mounted = ref(false);
    const filtersInUse = ref(0);
    const redrawCharts = ref(0);
    const clientCompaniesComboBoxQuery = ref('');
    const locationsComboBoxQuery = ref('');

    onMounted(() => {
        mounted.value = true;

        filtersInUse.value = getFiltersUsed.value;

        applyFormWatcher();
    });

    /**
     * Methods
     */
    function parseAndSign(amount) {
        if (!amount) {
            return;
        }

        return Math.sign(Number(amount));
    }

    function exportData() {
        saving.value = 'exporting';

        router.post(route('csv.compactor-monitors.reports.cumulative-portfolio-impact-summaries'), {...form}, {
            onFinish: () => { saving.value = 'passive'; }
        });
    }

    function comboDisplayValue(option) {
        if (Array.isArray(option)) {
            if (option.length > 1) {
                return `${option.length} filters selected`;
            } else if (option.length === 1) {
                return '1 filter selected';
            } else {
                return 'No filters selected';
            }
        }

        return option ?? 'No filters selected';
    }

    function applyFormWatcher() {
        watch(
            () => form,
            throttle(function() {
                if(mounted.value) {
                    router.get(route("compactor-monitors.reports.cumulative-portfolio-impact-summaries"), queryFilters.value, {
                        preserveState: true,
                        onSuccess: () => {
                            redrawCharts.value++;
                        }
                    });
                    filtersInUse.value = getFiltersUsed.value;
                }
            }, 150),
            {deep: true}
        );
    }

    function getHaulCostSeries() {
        return [
            {
                name: 'Before Pioneer',
                value: props.reportSummary.withoutMonitor?.haul_cost_sum_money ? parseInt(props.reportSummary.withoutMonitor.haul_cost_sum_money.amount) / 100 : null
            },
            {
                name: 'With Pioneer',
                value: props.reportSummary.data?.haul_cost_sum_money ? parseInt(props.reportSummary.data.haul_cost_sum_money.amount) / 100 : null
            }
        ];
    }

    function getPickupsRecordedSeries() {
        return [
            {
                name: 'Before Pioneer',
                value: props.reportSummary.withoutMonitor?.pickups_recorded_sum
            },
            {
                name: 'With Pioneer',
                value: props.reportSummary.data?.pickups_recorded_sum
            }
        ];
    }

    function getGHGEmissionsSeries() {
        return [
            {
                name: 'Before Pioneer',
                value: props.reportSummary.withoutMonitor?.ghg_emissions_sum
            },
            {
                name: 'With Pioneer',
                value: props.reportSummary.data?.ghg_emissions_sum
            }
        ];
    }

    function filterSelectedLocations() {
        if (form.client_companies.length === 0) {
            return;
        }

        let availableLocationIds = filteredLocationOptions.value.map(location => location.id);
        form.locations = form.locations.filter(location => availableLocationIds.includes(location));
    }

    function subNavTabs() {
	    return [
		    {name: 'Devices', href: route('compactor-monitors.index'), key: 'devices'},
		    {name: 'Reports', href: '', key: 'reports', subnav: [
                {name: 'Cumulative Monitor Impact', href: route('compactor-monitors.reports.cumulative-portfolio-impact-summaries'), key: 'cumulative-monitor-report'},
                {name: 'Cumulative Pickup Details', href: route('compactor-monitors.reports.cumulative-portfolio-pickup-details'), key: 'cumulative-pickup-details'},
            ]},
	    ]
    }

    /**
     * Computed
     */
    const filteredClientCompanyOptions = computed(() => {
        return clientCompaniesComboBoxQuery.value === ''
            ? props.clientCompanies
            : props.clientCompanies.filter((clientCompany) => {
                return clientCompany.name.toLowerCase().includes(clientCompaniesComboBoxQuery.value.toLowerCase());
            });
    });

    const sortedClientCompanyOptions = computed(() => {
        return filteredClientCompanyOptions.value.sort((a, b) => {
            let a_hash = (form.client_companies.includes(a.id) ? 0 : 1) + "_" + a.name + "_" + a.id;
            let b_hash = (form.client_companies.includes(b.id) ? 0 : 1) + "_" + b.name + "_" + b.id;

            return a_hash.localeCompare(b_hash);
        });
    });

    const filteredLocationOptions = computed(() => {
        let availableLocations = form.client_companies.length > 0
            ? props.locations.filter(location => form.client_companies.includes(location.client_company_id))
            : props.locations;

        return locationsComboBoxQuery.value === ''
            ? availableLocations
            : availableLocations.filter((location) => {
                return location.name.toLowerCase().includes(locationsComboBoxQuery.value.toLowerCase());
            });
    });

    const sortedLocationOptions = computed(() => {
        return filteredLocationOptions.value.sort((a, b) => {
            let a_hash = (form.locations.includes(a.id) ? 0 : 1) + "_" + a.name + "_" + a.id;
            let b_hash = (form.locations.includes(b.id) ? 0 : 1) + "_" + b.name + "_" + b.id;

            return a_hash.localeCompare(b_hash);
        });
    });

    const queryFilters = computed(() => {
        let query = { ...form};

        return has_search_filters(query) ? query : {remember: 'forget'};
    });

    const getFiltersUsed = computed(() => {
        const formFilters = [
            form.client_companies,
            form.locations,
            form.start_date,
            form.end_date
        ];

        return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
    });
</script>