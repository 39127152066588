<template>
    <Head :title="`Edit Service Creation - ${serviceActivity.service_id}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav aria-label="breadcrumbs" class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.index')" class="breadcrumb-link">Services</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('services.show', serviceActivity.service_id)" class="breadcrumb-link">{{ serviceActivity.service_id}}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit Service Creation</span>
        </nav>
    </Teleport>

    <div class="mt-8 mb-12">
        <div>
            <div class="rounded-md bg-blue-50 p-4 my-3">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <icon name="info-circle" class="size-5 text-blue-400 fill-current" />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-sm text-blue-700">Please note: Service creation will be backdated to the effective date if the effective date is in the past.</p>
                    </div>
                </div>
            </div>

            <div class="rounded-md bg-gray-100 p-4 my-3" v-if="hasApprovedInvoiceLineItems">
                <div class="flex">
                    <div class="flex-shrink-0">
                        <icon name="info-circle" class="size-5 text-gray-400 fill-current" />
                    </div>
                    <div class="ml-3 flex-1 md:flex md:justify-between">
                        <p class="text-sm text-gray-700">This service has vendor invoices attached to it that have already been approved. Some fields are unable to be edited.</p>
                    </div>
                </div>
            </div>

            <fieldset>
                <legend class="font-semibold text-xl text-blue-600">Service</legend>

                <div class="space-y-6">
                    <div class="grid grid-cols-1 sm:grid-cols-4 gap-x-4 gap-y-6 my-4">
                        <select-input v-model="form.type" label="Service Type" disabled markAsRequired>
                            <option :value="serviceActivity.serviceSnapshot.type" :key="serviceActivity.serviceSnapshot.type" selected>{{ serviceActivity.serviceSnapshot.type }}</option>
                        </select-input>

                        <revert-input class="col-span-2 md:col-span-1" label="Location" v-model="form.location_id" mark-as-required :disabled="hasApprovedInvoiceLineItems">
                            <search-input
                                v-model="form.location_id"
                                display-property="name"
                                route="json.locations.index"
                                placeholder="Search"
                                :route-params="{always_include: form.vendor_id, sort_by: 'name', sort_direction: 'asc', per_page: 50}"
                                :default-value="form.location"
                                :errors="errors['location_id']"
                                :force-valid="true"
                                @selected=locationSelected
                                markAsRequired
                                :disabled="hasApprovedInvoiceLineItems"
                            >
                                <template #searchResult="obj">
                                    <span class="block mb-1">{{ obj.result.name }}</span>
                                    <span class="block text-sm font-bold">{{ obj.result.clientCompany.name }}</span>
                                </template>
                            </search-input>
                        </revert-input>

                        <revert-input class="col-span-2 md:col-span-1" label="Effective Date" v-model="form.effective_date">
                            <date-input v-model="form.effective_date" :max="nextServiceActivity?.actual_date ? nextServiceActivity.actual_date : ''" class="col-span-2 md:col-span-1" placeholder="Effective Date" :errors="errors['effective_date']" @blur="syncEffectiveDateWithPickupStartDate" />
                        </revert-input>

                        <revert-input class="col-span-2 md:col-span-1" label="External Reference Id" v-model="form.external_reference_id">
                            <text-input
                                v-model="form.external_reference_id"
                                :errors="errors.external_reference_id"
                            />
                        </revert-input>
                    </div>
                </div>
            </fieldset>

            <fieldset class="mt-4">
                <legend class="font-semibold text-xl text-blue-600">Vendor</legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
                    <revert-input class="col-span-2 md:col-span-1" label="Vendor" v-model="form.vendor_id" mark-as-required :disabled="hasApprovedInvoiceLineItems">
                        <search-input
                            v-model="form.vendor_id"
                            display-property="name"
                            route="json.vendors.index"
                            placeholder="Search"
                            :errors="errors['vendor_id']"
                            :default-value="form.vendor"
                            @selected="(vendor) => form.vendor = vendor"
                            :disabled="hasApprovedInvoiceLineItems"
                        >
                            <template #searchResult="obj">
                                <span class="block mb-1">{{ obj.result.name }}</span>
                            </template>
                        </search-input>
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Vendor Account" v-model="form.vendor_account_id" mark-as-required :disabled="hasApprovedInvoiceLineItems">
                        <div v-if="form.vendor_id && !vendorAccounts.length" class="py-2 text-gray-700">No accounts available for this vendor</div>
                        <select-input-2 v-else v-model="form.vendor_account_id" :options="vendorAccounts" :errors="errors['vendor_account_id']" :disabled="hasApprovedInvoiceLineItems">
                            <template #option-label="vendorAccount">{{ vendorAccount.account_number }} - {{ vendorAccount.status }}</template>
                        </select-input-2>
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Vendor Contract" v-model="this.form.vendor_contract_selection" :disabled="hasApprovedInvoiceLineItems">
                        <select-input-2 v-model="this.form.vendor_contract_selection" :options="vendorContracts" :verbose="true" :errors="errors['vendor_contract_selection']" :disabled="hasApprovedInvoiceLineItems">
                            <template #option-label="vendorContract" :key="vendorContract.id">
                                <span v-if="vendorContract.id === 'missing-contract'">{{ vendorContract.display_id }}</span>
                                <span v-else-if="vendorContract.id === null">{{ vendorContract.display_id }}</span>
                                <span v-else>
                                {{ vendorContract.display_id }} - {{ vendorContract.status }} - Effective {{ vendorContract.effective_date }}
                                </span>
                            </template>
                        </select-input-2>
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Service Account Type" v-model="form.service_account_type">
                        <select-input v-model="form.service_account_type" :errors="errors['service_account_type']" markAsRequired>
                            <option>Permanent</option>
                            <option>Temporary</option>
                        </select-input>
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Service Schedule Type" v-model="form.service_schedule_type">
                        <select-input v-model="form.service_schedule_type" :errors="errors['service_schedule_type']" markAsRequired>
                            <option>Scheduled</option>
                            <option>On Call</option>
                            <option v-if="form.service_account_type==='Temporary'">One Time</option>
                        </select-input>
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Pickup Schedule" v-if="hasField['pickup_rrule']" v-model="form.pickup_rrule" :mark-as-required="form.service_schedule_type === 'Scheduled'">
                        <div v-if="form.service_schedule_type === 'Scheduled'">
                            <div class="text-sm py-3 text-gray-800">
                                {{ rrule_description }} <a href="#" class="" @click.prevent="showUpdatePickupRRuleModal"><icon name="edit" class="inline fill-current w-4 h-4"></icon></a>
                            </div>

                            <div v-if="errors['pickup_rrule'] || errors['average_pickups_per_week']" class="text-red-500 text-sm">
                                The pickup schedule is invalid
                            </div>
                        </div>
                        <div v-else class="py-3 font-gray-800 text-sm">
                            Cannot schedule {{ form.service_schedule_type.toLowerCase() ?? 'unscheduled '}} services
                        </div>
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Base Charge ($)" v-if="hasField['vendor_base_charge']" v-model="form.vendor_base_charge">
                        <money-input v-model="form.vendor_base_charge" placeholder="Base Charge" :defaultCurrency="$page.props.currency" :errors="errors['vendor_base_charge']" />
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Per Unit Charge ($)" v-if="hasField['vendor_per_unit_charge']" v-model="form.vendor_per_unit_charge">
                        <money-input v-model="form.vendor_per_unit_charge" placeholder="Per Unit Charge" :defaultCurrency="$page.props.currency" :errors="errors['vendor_per_unit_charge']" />
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Disposal Amount Per Ton ($)" v-if="hasField['vendor_disposal_amount_per_ton']" v-model="form.vendor_disposal_amount_per_ton">
                        <money-input v-model="form.vendor_disposal_amount_per_ton" placeholder="Disposal Amount Per Ton" :defaultCurrency="$page.props.currency" :errors="errors['vendor_disposal_amount_per_ton']" />
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Per Haul Amount ($)" v-if="hasField['vendor_per_haul_amount']" v-model="form.vendor_per_haul_amount">
                        <money-input v-model="form.vendor_per_haul_amount" placeholder="Per Haul Amount" :defaultCurrency="$page.props.currency" :errors="errors['vendor_per_haul_amount']" />
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Tons Included Per Haul" v-if="hasField['tons_included_per_haul']" v-model="form.tons_included_per_haul" mark-as-required>
                        <text-input  v-model="form.tons_included_per_haul" type="number" min="0" :errors="errors['tons_included_per_haul']" />
                    </revert-input>
                </div>
            </fieldset>

	        <div v-if="serviceActivity.serviceSnapshot.type !== 'Device' && serviceActivity.serviceSnapshot.type !== 'Doorstep'" class="mt-6">
		        <span class="font-semibold text-xl text-d-blue-500">Bin</span>
		        <div class="grid gap-x-4 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 mt-4">
			        <revert-input class="col-span-2 md:col-span-1" label="Equipment Label" v-model="form.label">
				        <text-input v-model="form.label" maxlength="50" class="col-span-1" :errors="errors['label']"/>
			        </revert-input>
		        </div>
		        <revertible-bin :property-type="serviceActivity.serviceSnapshot.location?.property_type" :bin-types="binTypes" :material-types="materialTypes" :asset-owners="assetOwners" v-model="form.bin" />
	        </div>
            <fieldset v-if="serviceActivity.serviceSnapshot.type === 'Doorstep'" class="mt-4">
                <legend class="block font-semibold text-xl text-blue-600">Bin</legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4">
                    <revert-input class="col-span-2 md:col-span-1" label="Quantity" v-model="form.bin.quantity">
                        <text-input class="col-span-2 md:col-span-1" v-model="form.bin.quantity" type="number" :errors="errors['bin.quantity']" />
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Bin Size" v-model="form.bin.size">
                        <text-input class="col-span-2 md:col-span-1" v-model="form.bin.size" type="number" :errors="errors['bin.size']" />
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Size metric" v-model="form.bin.size_metric">
                        <select-input class="col-span-2 md:col-span-1" v-model="form.bin.size_metric" :errors="errors['bin.size_metric']">
                            <option value="yards">Yards</option>
                            <option value="gallons">Gallons</option>
                            <option value="foot">Feet</option>
                        </select-input>
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Material Type" v-model="form.bin.material_type">
                        <select-input class="col-span-2 md:col-span-1" v-model="form.bin.material_type" :errors="errors['bin.material_type']" >
                            <option v-for="material_type in materialTypes" :value="material_type.name" :key="material_type.name">{{ material_type.name }}</option>
                        </select-input>
                    </revert-input>
                </div>
            </fieldset>

            <fieldset v-if="$page.props.permissions.accessRevenueManagement" class="mt-4">
                <legend class="font-semibold text-xl text-blue-600">Client</legend>

                <div class="grid gap-x-4 gap-y-6 grid-cols-2 md:grid-cols-3 lg:grid-cols-4 my-4">
                    <revert-input class="col-span-2 md:col-span-1" label="Client Contract" v-model="form.client_contract_id">
                        <select-input-2 class="col-span-2 md:col-span-1" v-model="form.client_contract_id" :options="clientContracts" :errors="errors['client_contract_id']">
                            <template #option-label="option">{{ option.id }}</template>
                            <template #option-display="{option, selected}">
                                <div :class="{'font-semibold': selected}" class="block">
                                    <div>{{ option.id }}</div>
                                    <div class="text-xs">Integration: {{ option.integration_date }}</div>
                                </div>
                                <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                    <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                                </span>
                            </template>
                        </select-input-2>
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Is Pass Through?" v-model="form.is_pass_through" mark-as-required>
                        <select-input v-model="form.is_pass_through" class="col-span-1" :errors="errors['is_pass_through']">
                            <option :value="null">Unsure</option>
                            <option :value="true">Yes</option>
                            <option :value="false">No</option>
                        </select-input>
                    </revert-input>

                    <revert-input v-if="hasField['client_billing_frequency']" :disabled="form.is_pass_through === true" class="col-span-2 md:col-span-1" label="Client Billing Frequency" v-model="form.client_billing_frequency" mark-as-required>
                        <select-input-2 v-model="form.client_billing_frequency" :options="clientBillingFrequencies" :errors="errors['client_billing_frequency']"></select-input-2>
                    </revert-input>

                    <revert-input v-if="hasField['client_base_charge'] && form.is_pass_through === false" class="col-span-2 md:col-span-1" label="Base Charge ($)" v-model="form.client_base_charge">
                        <money-input v-model="form.client_base_charge" placeholder="Base Charge" :defaultCurrency="$page.props.currency" :errors="errors['client_base_charge']" />
                    </revert-input>

                    <revert-input v-if="hasField['client_per_unit_charge'] && form.is_pass_through === false" class="col-span-2 md:col-span-1" label="Per Unit Charge ($)" v-model="form.client_per_unit_charge">
                        <money-input v-model="form.client_per_unit_charge"  placeholder="Per Unit Charge" :defaultCurrency="$page.props.currency" :errors="errors['client_per_unit_charge']" />
                    </revert-input>

                    <revert-input v-if="hasField['client_disposal_amount_per_ton'] && form.is_pass_through === false" class="col-span-2 md:col-span-1" label="Disposal Amount Per Ton ($)" v-model="form.client_disposal_amount_per_ton">
                        <money-input v-model="form.client_disposal_amount_per_ton" placeholder="Disposal Amount Per Ton" :defaultCurrency="$page.props.currency" :errors="errors['client_disposal_amount_per_ton']" />
                    </revert-input>

                    <revert-input v-if="hasField['client_per_haul_amount'] && form.is_pass_through === false" class="col-span-2 md:col-span-1" label="Per Haul Amount ($)" v-model="form.client_per_haul_amount">
                        <money-input v-model="form.client_per_haul_amount" placeholder="Per Haul Amount" :defaultCurrency="$page.props.currency" :errors="errors['client_per_haul_amount']" />
                    </revert-input>

                    <revert-input class="col-span-2 md:col-span-1" label="Label" v-model="form.label">
                        <text-input v-model="form.label" :errors="errors['label']" />
                    </revert-input>
                </div>
            </fieldset>

            <hr class="my-8"/>

            <textarea-input class="my-6 w-full" :errors="errors['internal_change_notes']" label="Please tell why this change is being entered." v-model="internal_change_notes" markAsRequired />

            <div class="flex w-full mt-8 flex-row-reverse">
                <loading-button :loading="saving" class="btn btn-orange ml-3" @click="replaceServiceCreation">
                    Save
                </loading-button>
            </div>
        </div>

        <!-- Pickup RRule Modal -->
        <modal v-if="serviceActivity.serviceSnapshot.type === 'Commercial' || serviceActivity.serviceSnapshot.type === 'Doorstep' || serviceActivity.serviceSnapshot.type === 'Rolloff'" class="max-w-2xl" ref="updatePickupRRuleModal" tabindex="-1" role="dialog">
            <template #modal-header="{close}">
                <div class="p-4 border-b border-gray-400 flex justify-between">
                    <div class="my-1 text-2xl">
                        Update Pickup Schedule
                    </div>

                    <button type="button" class="focus:outline-none" aria-label="Close" @click="close">
                        <icon name="times" class="fill-current inline w-6 h-6" />
                    </button>
                </div>
            </template>

            <template #modal-body>
                <div class="p-4">
                    <r-rule-input
                        ref="rRuleInput"
                        v-model:modelValue="form.pickup_rrule"
                        v-model:weeklyAverage="form.average_pickups_per_week"
                        v-model:description="rrule_description"
                        start-date-label="First Pickup"
                        weekly-average-label="Average Pickups Per Week"
                    />
                </div>
            </template>

            <template #modal-footer="{close}">
                <div class="p-4">
                    <button type="button" class="btn btn-gray mr-2" @click="close">Close</button>
                </div>
            </template>
        </modal>
    </div>
</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Modal from '@/Shared/Modal.vue';
    import MoneyInput from '@/Shared/MoneyInput.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import RevertibleBin from "@/Shared/Services/RevertibleBin.vue";
    import RevertInput from '@/Shared/RevertInput.vue';
    import RRuleInput from '@/Shared/RRuleInput.vue';
    import SearchInput from '@/Shared/SearchInput.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import SelectInput2 from '@/Shared/SelectInput2.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import axios from "axios";
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            DateInput,
            Icon,
            LoadingButton,
            Modal,
            MoneyInput,
            Pagination,
            RevertibleBin,
            RevertInput,
            RRuleInput,
            SearchInput,
            SelectInput,
            SelectInput2,
            TextareaInput,
            TextInput,
            ToggleSwitchInput,
            Head,
        },

        props: {
            assetOwners: {
                type: Array,
                required: true
            },

            binTypes: {
                type: Array,
                required: true
            },

            clientBillingFrequencies: {
                type: Array,
                required: true
            },

            errors: {
                type: Object,
                default: () => ({})
            },

            initialClientContracts: {
                type: Array,
                default: []
            },

            initialVendorAccounts: {
                type: Array,
                default: []
            },

            initialVendorContracts: {
                type: Array,
                default: []
            },

            materialTypes: {
                type: Array,
                required: true
            },

            nextServiceActivity: {
                type: Object,
                default: null
            },

            serviceActivity: {
                type: Object,
                required: true
            },

            serviceTypes: {
                type: Array,
                required: true
            },

            hasApprovedInvoiceLineItems: {
                type: Boolean,
                required: true
            }
        },

        data() {
            return {
                form: {...this.serviceActivity.serviceSnapshot, bin: {...this.serviceActivity.serviceSnapshot.bin}, location: {...this.serviceActivity.serviceSnapshot.location}, vendor: {...this.serviceActivity.serviceSnapshot.vendor}},
                saving: false,
                steps: [
                    { id: 1, name: 'Service Type and Location', href: '#', status: 'current' },
                    { id: 2, name: 'Service Details', href: '#', status: 'upcoming' },
                ],
                activeStep: 1,
                clientContracts: [],
                internal_change_notes: null,
                vendorAccounts: [],
                vendorContracts: [],
                mounted: false,
                missingVendorContract: {id: 'missing-contract', display_id: "Missing contract"},
                noVendorContract: {id: null, display_id: 'No contract'},
                rrule_description: null,
            }
        },

        methods: {
            showUpdatePickupRRuleModal() {
                this.$refs.updatePickupRRuleModal.show();
            },

            replaceServiceCreation() {
                this.saving = true;

                let route = this.$route('services.service-creation-changes.update', {serviceId: this.serviceActivity.service_id, serviceActivityId: this.serviceActivity.id});

                this.$inertia
                    .put(route, {
                        ...this.form,
                        'internal_change_notes': this.internal_change_notes
                    }, {
                        onFinish: () => this.saving = false
                    });
            },

            syncEffectiveDateWithPickupStartDate() {
                this.$refs.rRuleInput.setStartDate(this.form.effective_date);
            },

            locationSelected(location) {
                this.form.location = location;

                if (!location) {
                    this.clientContracts = [];

                    return;
                }

                // this is for reverting the search box
                if (location.id === this.serviceActivity.serviceSnapshot.location_id) {
                    this.form.location = {...this.serviceActivity.serviceSnapshot.location}
                }

                if (this.$page.props.permissions.accessRevenueManagement) {
                    axios.get(this.$route('json.client-contracts.index', {client_company_id: this.form.location.client_company_id})).then(response => {
                        this.clientContracts = response.data.data;
                    })
                } else {
                    this.clientContracts = [];
                }
            }
        },

        watch: {
			'form.vendor_contract_selection'(newValue, oldValue) {
				if (newValue === 'missing-contract') {
					this.form.vendor_contract_id = null;
				} else {
					this.form.vendor_contract_id = newValue;
				}
			},

            'form.location_id'(newValue, oldValue) {
                this.form.client_contract_id = null;
                this.form.clientContract = null;

                if (!newValue) {
                    this.locationSelected(null);
                }
            },

            'form.vendor_id'() {
                this.form.vendor_account_id = null;
                this.form.vendor_contract_id = null;

                if (this.form.vendor_id) {
                    axios.get(this.$route('json.vendor-accounts.index', {vendor_id: this.form.vendor_id, per_page: 500})).then(response => {
                        this.vendorAccounts = response.data.data;
                    })

                    axios.get(this.$route('json.vendor-contracts.index', {vendor_id: this.form.vendor_id, per_page: 500})).then(response => {
                        this.vendorContracts = [this.noVendorContract, this.missingVendorContract, ...response.data.data];
                    })
                } else {
                    this.vendorAccounts = [];
                    this.vendorContracts = [];
                }

                // this is for reverting the search box
                if (this.form.vendor_id === this.serviceActivity.serviceSnapshot.vendor_id) {
                    this.form.vendor = {...this.serviceActivity.serviceSnapshot.vendor}
                }
            },

	        'form.is_pass_through'(newValue, oldValue) {
		        if (newValue === true && this.serviceActivity.service.type !== 'Rolloff') {
			        this.form.client_billing_frequency = 'Per Vendor Invoice';
		        } else {
			        this.form.client_billing_frequency = this.service.client_billing_frequency;
		        }
	        },
        },

        mounted() {
            this.clientContracts = [...this.initialClientContracts];
            this.vendorAccounts = [...this.initialVendorAccounts];
            this.vendorContracts = [this.noVendorContract, this.missingVendorContract, ...this.initialVendorContracts];
            this.mounted = true;
        },

        computed: {
            hasField() {
                return {
                    'average_pickups_per_week':         ['Commercial', 'Doorstep', 'Rolloff'].includes(this.serviceActivity.serviceSnapshot.type),
                    'client_base_charge':               ['Device', 'Rental', 'Doorstep', 'Commercial'].includes(this.serviceActivity.serviceSnapshot.type),
                    'client_billing_frequency':         this.serviceActivity.serviceSnapshot.type !== 'Rolloff',
                    'client_disposal_amount_per_ton':   this.serviceActivity.serviceSnapshot.type === 'Rolloff',
                    'client_per_haul_amount':           this.serviceActivity.serviceSnapshot.type === 'Rolloff',
                    'client_per_unit_charge':           this.serviceActivity.serviceSnapshot.type === 'Doorstep',
                    'pickup_rrule':                     ['Commercial', 'Doorstep', 'Rolloff'].includes(this.serviceActivity.serviceSnapshot.type),
                    'tons_included_per_haul':           this.serviceActivity.serviceSnapshot.type === 'Rolloff',
                    'vendor_base_charge':               ['Device', 'Rental', 'Doorstep', 'Commercial'].includes(this.serviceActivity.serviceSnapshot.type),
                    'vendor_disposal_amount_per_ton':   this.serviceActivity.serviceSnapshot.type === 'Rolloff',
                    'vendor_per_haul_amount':           this.serviceActivity.serviceSnapshot.type === 'Rolloff',
                    'vendor_per_unit_charge':           this.serviceActivity.serviceSnapshot.type === 'Doorstep',
                }
            },
        }
    }
</script>
