<template>
	<div class="block lg:hidden max-w-7xl mx-auto sm:px-6 md:flex md:items-center md:grid md:grid-cols-3 3xl:max-w-screen-2xl lg:px-1">
		<aside class="flex gap-y-8 pt-6 lg:col-span-3 mb-3">
			<nav>
				<button @click="$refs.navigationSlideOver.show()" class="flex btn btn-gray">
					<span class="text-lg text-d-orange-500"> {{ $filters.capitalize(displayTab) }}</span>
					<icon name="menu" class="h-6 w-6 ml-4 text-d-orange-500 fill-current" @click="$refs.navigationSlideOver.show()"></icon>
				</button>
			</nav>
		</aside>
	</div>

	<div v-bind="$attrs" class="hidden lg:block max-w-7xl mx-auto sm:px-6 md:flex 3xl:max-w-screen-2xl lg:px-0 4xl:max-w-none border-b">
	    <aside class="flex gap-y-8 pt-6 hidden lg:block grid">
		    <nav class="grid grid-cols-2 text-md text-center gap-5">
			    <div class="grid grid-cols-5">
			    <inertia-link key="overview" :href="$route('compactor-monitors.overview', compactorMonitor.id)" class="group border-b-[3px] px-3 py-2 flex items-center justify-center font-medium" :class="classBindings('overview')" :aria-current="'overview' === currentTab">
				    <span class="">Overview</span>
			    </inertia-link>

			    <inertia-link  key="fullness" :href="$route('compactor-monitors.fullness', compactorMonitor.id)" class="group border-b-[3px] px-3 py-2 flex items-center justify-center font-medium" :class="classBindings('fullness')" :aria-current="'fullness' === currentTab">
				    <span class="">Fullness</span>
			    </inertia-link>

			    <inertia-link key="maintenance" :href="$route('compactor-monitors.maintenance', compactorMonitor.id)" class="group border-b-[3px] px-3 py-2 flex items-center justify-center font-medium" :class="classBindings('maintenance')" :aria-current="'maintenance' === currentTab">
				    <span class="">Maintenance</span>
			    </inertia-link>

			    <span
				    key="reporting"
				    href=""
				    class="group border-b-[3px] px-3 py-2 flex items-center justify-center font-medium relative"
				    :class="classBindings('reports')"
				    :aria-current="'reports' === currentTab"
				    @click.prevent.stop="toggleReports"
				    @blur="toggleReports()"
			    >
				    <dropdown-link>
					    <template #link>
	                        <span class="mx-1">Reports</span>
					    </template>

					    <template #dropdown>
						    <div class="dropdown w-72 text-left mt-3 -ml-4">
							    <inertia-link key="monthly-impact-summaries" :href="$route('compactor-monitors.reports.monthly-impact-summaries', compactorMonitor.id)" class="dropdown-link">
								    <span :class="{'bg-white-100 border-d-orange-500 text-d-orange-500': currentTab === 'monthly-impact-summaries'}">
									    Monitor Impact
								    </span>
							    </inertia-link>
							    <inertia-link key="pickup-details" :href="$route('compactor-monitors.reports.pickup-details', compactorMonitor.id)" class="dropdown-link">
								    <span :class="{'bg-white-100 border-d-orange-500 text-d-orange-500': currentTab === 'pickup-details'}">
									    Pickup Details
								    </span>
							    </inertia-link>
						    </div>
					    </template>
				    </dropdown-link>
			    </span>


			    <inertia-link key="autopilot" :href="$route('compactor-monitors.autopilot', compactorMonitor.id)" class="group border-b-[3px] px-3 py-2 flex items-center justify-center  font-medium" :class="classBindings('autopilot')" :aria-current="'autopilot' === currentTab">
				    <span class="">Autopilot</span>
                </inertia-link>
			    </div>
		    </nav>
	    </aside>
    </div>
	<slide-over ref="navigationSlideOver">
		<template #header="{close}">
			<div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
				<div class="flex items-start justify-between">
					<h2 class="text-lg font-medium text-white text-wrap" id="slide-over-title">{{ compactorMonitor.monitoredService.location.name }}</h2>
					<div class="ml-3 flex h-7 items-center">
						<button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
							<span class="sr-only">Close panel</span>
							<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
								<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>

				</div>
			</div>
		</template>

		<template #body>
			<div class="col-span-6 sm:col-span-1 text-left">
				<inertia-link key="overview" :href="$route('compactor-monitors.overview', compactorMonitor.id)" class="group py-2 flex items-start text-sm font-medium" :class="classBindings('overview')"
				              :aria-current="'overview' === currentTab">
					<span class="text-lg">Overview</span>
				</inertia-link>

				<inertia-link key="fullness" :href="$route('compactor-monitors.fullness', compactorMonitor.id)" class="group mx-auto py-2 flex items-start text-sm font-medium" :class="classBindings('fullness')"
				              :aria-current="'fullness' === currentTab">
					<span class="text-lg">Fullness</span>
				</inertia-link>

				<inertia-link key="maintenance" :href="$route('compactor-monitors.maintenance', compactorMonitor.id)" class="group mx-auto w-auto py-2 flex items-start text-sm font-medium" :class="classBindings('maintenance')"
				              :aria-current="'maintenance' === currentTab">
					<span class="text-lg">Maintenance</span>
				</inertia-link>

				<span key="reporting" href="" class="group py-1 w-auto -space-x-2 flex items-start text-sm font-medium relative -my-1"
				      :class="classBindings('reports')"
				      :aria-current="'reports' === currentTab"
				      @click.prevent.stop="toggleReports"
				      @blur="toggleReports()"
				>
		            <dropdown-link>
				        <template #link>
				            <span class="flex items-center text-lg -ml-2">Reports
				                <icon :name="expandReports ? 'chevron-down' : 'chevron-right'" class="h-4 w-4 ml-1" />
				            </span>
				        </template>

				        <template #dropdown>
				            <div class="dropdown w-72 text-left mt-3 ">
				                <inertia-link
					                key="monthly-impact-summaries" :href="$route('compactor-monitors.reports.monthly-impact-summaries', compactorMonitor.id)" class="dropdown-link">
					                <span :class="{'bg-white-100 border-d-orange-500 text-d-orange-500': currentTab === 'monthly-impact-summaries'}">
						                Monitor Impact
					                </span>
				                </inertia-link>
				                <inertia-link key="pickup-details" :href="$route('compactor-monitors.reports.pickup-details', compactorMonitor.id)" class="dropdown-link">
				                    <span :class="{'bg-white-100 border-d-orange-500 text-d-orange-500': currentTab === 'pickup-details'}">
					                    Pickup Details
				                    </span>
				                </inertia-link>
				            </div>
				        </template>
		            </dropdown-link>
	            </span>

				<inertia-link key="autopilot" :href="$route('compactor-monitors.autopilot', compactorMonitor.id)" class="group  py-2 flex items-start text-sm font-medium" :class="classBindings('autopilot')"
				              :aria-current="'autopilot' === currentTab">
					<span class="text-lg">Autopilot</span>
				</inertia-link>
			</div>
		</template>
	</slide-over>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from "../../../Shared/LoadingButton.vue";
    import DropdownLink from "../../../Shared/DropdownLink.vue";
    import SlideOver from "../../../Shared/SlideOver.vue";
    import {capitalize} from "lodash-es";

export default {
    components: {
	    SlideOver,
	    DropdownLink,
        LoadingButton,
        Icon,
    },

    props: {
        errors: {
            type: Object,
            default: () => ({})
        },

        compactorMonitor: {
            type: Object,
            required: true
        },

	    currentTab: {
		    type: String,
		    required: true
	    }
    },

    data() {
        return {
            mounted: false,
	        expandReports: false,
	        reports: [
		        'monthly-impact-summaries',
		        'pickup-details',
	        ]
        }
    },

    mounted() {
        this.mounted = true;
	    document.addEventListener('click', this.clickOutsideHandler);
    },

	beforeDestroy() {
		document.removeEventListener('click', this.clickOutsideHandler);
	},

    unmounted() {
        Echo.leave(`compactor_monitors.${this.compactorMonitor.id}`);
    },

    methods: {
	    capitalize,
		toggleReports() {
			this.expandReports = !this.expandReports;
		},

	    classBindings(linkName) {
		    if (linkName === this.displayTab) {
			    return 'bg-white-100 border-d-orange-500 text-d-orange-500';
		    } else {
			    return 'text-accent-900 border-transparent hover:border-d-accent-500 hover:bg-d-accent-100'
		    }
	    },

	    clickOutsideHandler(e) {
		    if (!this.$refs.dropdown?.contains(e.target) && this.expandReports) {
			    this.expandReports = false;
		    }
	    }
    },
	computed: {
		displayTab() {
			if (this.reports.includes(this.currentTab)) {
	            return 'reports';
		    } else {
				return this.currentTab;
			}
	    }
    }
}
</script>