<template>
    <Head :title="`Edit Client Account - ${clientAccount.name}`" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-accounts.index')" class="breadcrumb-link">Client Accounts</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <inertia-link :href="$route('client-accounts.show', [clientAccount.id])" class="breadcrumb-link">{{ clientAccount.name }}</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Edit</span>
        </nav>
    </Teleport>

    <form @submit.prevent="submitForm" id="edit-client-account-form" class="space-y-8">
        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Basic Info</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Basic settings and info for this account.</p>
                </div>
            </legend>

            <div class="grid grid-cols-4 gap-y-6 gap-x-4">
                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <label class="form-label">Client Company</label>
                    <inertia-link :href="$route('client-companies.show', clientAccount.client_company_id)" class="link">
                        {{ clientAccount.clientCompany.name }}
                    </inertia-link>
                </div>

                <text-input
                    v-model="form.name"
                    label="Name"
                    placeholder="Name"
                    class="col-span-4 sm:col-span-2 md:col-start-1"
                    :errors="errors.name"
                    mark-as-required
                />
                <text-input
                    v-model="form.account_number"
                    label="Account Number"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    :help-text="helpTextOn ? 'Changing this number will not change the account number on invoices created in the past' : ''"
                    :errors="errors.account_number"
                    mark-as-required
                />
                <text-input
                    v-model="form.primary_contact_name"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Primary Contact Name"
                    placeholder="John Doe"
                    :errors="errors.primary_contact_name"
                    mark-as-required
                />
                <text-input
                    v-model="form.primary_telephone_number"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Primary Telephone Number"
                    placeholder="(401) 123-456"
                    :errors="errors.primary_telephone_number"
                />
                <text-input
                    v-model="form.primary_email_address"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Primary Email Address"
                    placeholder="yourcustomer@theirdomain.com"
                    :errors="errors.primary_email_address"
                />

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <select-input
                        v-model="form.default_payment_method"
                        class="col-span-4 sm:col-span-2 md:col-span-1"
                        label="Default Payment Method"
                        :errors="errors.default_payment_method"
                    >
                        <option v-for="paymentMethod in paymentMethods" :key="paymentMethod" :value="paymentMethod">
                            {{ paymentMethod }}
                        </option>
                    </select-input>
                </div>

                <text-input
                    v-model="form.purchase_order"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Purchase Order"
                    placeholder="Purchase Order"
                    :errors="errors.purchase_order"
                />
                <text-input
                    v-model="form.payment_terms"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    label="Payment Terms"
                    placeholder="Payment Terms"
                    :errors="errors.payment_terms"
                    mark-as-required
                />
                
                <text-input
                    v-model="form.external_reference_id"
                    label="External Reference Id"
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    :errors="errors.external_reference_id"
                />
                <textarea-input
                    class="col-span-4 md:col-span-3"
                    label="Billing Address"
                    v-model="form.billing_address"
                    :autosize="true"
                />
            </div>
        </fieldset>

        <fieldset class="space-y-8 sm:space-y-5">
            <legend class="pt-8 sm:pt-10">
                <div>
                    <h3 class="text-lg leading-6 font-medium text-gray-900">Client Invoicing</h3>
                    <p class="mt-1 max-w-2xl text-sm text-gray-500">Client invoice settings for this account.</p>
                </div>
            </legend>

            <div class="grid grid-cols-4 gap-y-6 gap-x-4">
                <select-input
                    class="col-span-4 sm:col-span-2 md:col-span-1"
                    v-model="form.invoice_template_view"
                    label="Invoice Template View"
                    :errors="errors.invoice_template_view"
                >
                    <template v-if="templateViews">
                        <option v-for="templateView in templateViews" :key="templateView" :value="templateView">
                            {{ templateView }}
                        </option>
                    </template>
                </select-input>

                <text-input
                    v-model="form.other_invoice_recipients"
                    class="col-span-4 sm:col-start-1 sm:col-span-2"
                    label="Other Invoice Recipients"
                    placeholder="johndoe@fake.com, jane@somedomain.com"
                    :errors="errors.other_invoice_recipients"
                />

                <div class="col-span-4 sm:col-span-2 md:col-span-1 md:col-start-1">
                    <toggle-switch-input label="Show Group Name In Invoice" v-model="form.show_account_name_in_invoice" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
	                <toggle-switch-input label="Show Service Location Names In Invoice" v-model="form.show_service_location_names_in_invoice" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
	                <toggle-switch-input label="Single Invoice Per Period" v-model="form.single_invoice_per_period" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
	                <toggle-switch-input label="Exempt From Finance Charge" v-model="form.exempt_from_finance_charge" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
	                <toggle-switch-input label="Bill Unaudited Vendor Invoice Line Items" v-model="form.use_initial_line_items_for_client_billing" />
                </div>

                <div class="col-span-4 sm:col-span-2 md:col-span-1">
                    <toggle-switch-input label="Set Invoice as Due Upon Receipt" v-model="form.invoices_due_upon_receipt" />
                </div>

                <textarea-input class="col-span-4" v-model="form.invoice_remittance_text" label="Invoice Remittance Text" :autosize="true" />
            </div>
        </fieldset>

        <div>
            <loading-button :loading="state === 'saving'" class="btn btn-orange">
                Save Changes
            </loading-button>
        </div>
    </form>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import Pagination from '@/Shared/Pagination.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import TextInput from '@/Shared/TextInput.vue';
    import TextareaInput from '@/Shared/TextareaInput.vue';
    import ToggleSwitchInput from '@/Shared/ToggleSwitchInput.vue';
    import { Head } from '@inertiajs/vue3';

    export default {
        components: {
            Icon,
            LoadingButton,
            Pagination,
            SelectInput,
            TextInput,
            TextareaInput,
            ToggleSwitchInput,
            Head,
        },
        
        props: {
            errors: {
                type: Object,
                default: () => ({})
            },

            clientAccount: {
                type: Object,
                required: true,
            },

            clientCompanies: {
                type: Array,
                required: true,
            },

            paymentMethods: {
                type: Object,
                required: true,
            },

            templateViews: Object,

            tenant: Object,
        },

        data() {
            return {
                form: {... this.clientAccount},
                state: 'passive',
                helpTextOn: false,
                mounted: false,
            }
        },

        mounted() {
            this.mounted = true;
        },

        methods: {
            submitForm() {
                if (this.state === 'passive') {
                    this.state = 'saving';

                    this.$inertia
                        .put(this.$route('client-accounts.update', this.clientAccount.id), this.form, { 
                            preserveScroll: true,
                            onFinish: () => this.state = 'passive' 
                        });
                }
            }
        },
    }
</script>