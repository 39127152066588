<template>
    <Head title="Monthly Impact Summaries" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">
                Home
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('compactor-monitors.index')" class="breadcrumb-link">
                Compactor Monitors
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('compactor-monitors.overview', compactorMonitor.id)" class="breadcrumb-link">
                {{ compactorMonitor.monitoredService?.location.name ?? compactorMonitor.id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>
                Reports
            </span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>
                Monthly Impact Summaries
            </span>
        </nav>
    </Teleport>

	<div class="lg:sticky lg:top-0 bg-white z-20">
		<CompactorMonitorHeader :compactor-monitor="compactorMonitor" />
		<SubNav :compactor-monitor="compactorMonitor" current-tab="monthly-impact-summaries"/>
	</div>

	<div class="max-w-7xl mx-auto 3xl:max-w-screen-2xl my-0 4xl:max-w-none">
		<div class="mx-2 md:mx-3 lg:mx-4">
			<div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2 my-1 items-center">
				<div class="text-3xl mt-auto flex">
					Monitor Impact
					<tooltip-icon class="mt-1 ml-2" tooltip="Details the net impact of the Pioneer monitor compared to the baseline data"/>
				</div>
				<div class="print:hidden lg:grid md:grid-cols-4 lg:grid-cols-7 inline-flex col-start-1 col-span-2 sm:col-span-1 md:col-start-2 justify-center mr-4 md:mr-0 justify-self-end mt-auto">
					<button class="hidden lg:block lg:col-span-2 mt-6 btn btn-orange font-semibold justify-center mr-2 whitespace-nowrap" @click="$refs.reportBaselineDataModal.show()">Edit Baseline Data</button>
					<button type="button" class="lg:col-span-2 mt-6 mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white md:font-semibold items-center border border-gray-400  rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.reportFilterSlideOver.show()">
	                    <span class="inline-flex px-3 py-2">
	                        <icon name="filter" class="w-6 h-6 md:w-5 md:h-5 text-gray-400 fill-current" />
	                        <span class="hidden md:block ml-2">Filters</span>
	                    </span>
						<span class="relative px-3 py-2  w-full items-center bg-d-accent-100  md:font-semibold text-d-accent-900 rounded-r-md">
	                        {{ filtersInUse }}
	                    </span>
					</button>

					<inertia-link :href="$route('compactor-monitors.reports.monthly-impact-summaries', compactorMonitor.id, {remember: 'forget'})" class="col-span-1 btn btn-gray mt-6 mr-2 text-md text-gray-800 justify-center">Clear</inertia-link>

					<loading-button :loading="state === 'exporting'" class="hidden md:block col-span-2 mt-6 btn btn-gray font-semibold justify-center w-auto" @click="exportData">
						Export to CSV
					</loading-button>
				</div>
				<div :key="redrawCharts" class="block print:hidden md:col-span-2 mb-5">
					<div id="chart-container" class="grid md:grid-cols-2 lg:grid-cols-4 flex shadow py-5 rounded-lg">
						<div class="px-6 print:px-0 border-r print:my-0">
							<compactor-monitor-reporting-dashboard-bar-chart
								:series="getGrossSpendSeries()"
								:metric-data="reportSummary.delta.gross_spend_amount_sum_money?.amount"
								:delta-percentage="$filters.roundWithPrecision(reportSummary.delta?.gross_spend_amount_sum_pct, 0)"
								trend-prefix="$"
								trend-title="Hauling Cost"
								:is-inverse-trend="true"
								container-name="gross-spend-chart"
								class="col-span-1 print:mx-0"
								ref="gross-spend-chart"/>
						</div>
						<div class="px-6 print:px-0 border-r print:my-0">
							<compactor-monitor-reporting-dashboard-bar-chart
								:series="getHaulsRecordedSeries()"
								:metric-data="$filters.roundWithPrecision(reportSummary.delta?.hauls_recorded_sum, 2)"
								:delta-percentage="$filters.roundWithPrecision(reportSummary.delta?.hauls_recorded_sum_pct, 0)"
								container-name="hauls-chart"
								trend-suffix="hauls"
								trend-title="Pickups"
								:is-inverse-trend="true"
								class="col-span-1 print:mx-0 print:w-full"
								ref="gross-spend-chart"/>
						</div>
						<div class="px-6 print:px-0 border-r print:my-0">
							<compactor-monitor-reporting-dashboard-bar-chart
								:series="getGHGEmissionsSeries()"
								:metric-data="$filters.roundWithPrecision(reportSummary.delta?.ghg_emissions, 2)"
								:delta-percentage="$filters.roundWithPrecision(reportSummary.delta?.ghg_emissions_pct, 0)"
								trend-title="GHG Emissions"
								trend-suffix="pounds"
								:is-inverse-trend="true"
								container-name="ghg-emissions-chart"
								class="col-span-1 print:mx-0 print:w-full"
								ref="gross-spend-chart"/>
						</div>
						<div class="px-6 print:px-0 print:my-0">
							<compactor-monitor-reporting-dashboard-bar-chart
								:series="getPickupWeightSeries()"
								:metric-data="$filters.roundWithPrecision(reportSummary.delta?.avg_tons_per_haul, 2)"
								:delta-percentage="$filters.roundWithPrecision(reportSummary.delta?.avg_tons_per_haul_pct, 0)"
								trend-title="Average Pickup Weight"
								metric-type="weight"
								trend-suffix="ton"
								container-name="pickup-weight-chart"
								class="col-span-1 print:mx-0 print:w-full"
								ref="gross-spend-chart"/>
						</div>
						<div class="text-xxs hidden print:block col-span-4">BEFORE PIONEER values are estimated based upon the average baseline values of {{ compactorMonitor.monitorConfiguration?.pre_monitor_avg_hauls_per_month ?? preMonitorValues.avg_hauls_per_month }} hauls per month and
							{{ compactorMonitor.monitorConfiguration?.pre_monitor_avg_tons_per_month ? compactorMonitor.monitorConfiguration?.pre_monitor_avg_tons_per_month : preMonitorValues.avg_tons_per_haul }} tons per haul.
						</div>
					</div>
				</div>

			</div>

			<div class="shadow rounded p-5">
				<div class="relative w-full overflow-x-auto overflow-x-auto p-5">
					<table class="table table-condensed overflow-x-scroll  p-4" ref="table">
						<thead class="font-semibold">
						<tr>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Month</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Average Haul Cost</th>
	                        <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Rate Calculation</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Hauls Recorded</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Tons Disposed</th>
	                        <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500 ">Weight Measurement</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Average Tons per Haul</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Hauls Eliminated</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">GHG Eliminated (pounds)</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Gross Savings ($)</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Gross Savings (%)</th>
						</tr>
						</thead>

						<tbody>
						<tr v-for="data in reportData.data">
							<td class="!border-gray-200">
	                            <inertia-link :href="pickupDetailsUrl(data)" class="link">
								    {{ data.month }}
	                            </inertia-link>
							</td>
							<td class="!border-gray-200">{{ data.avg_haul_cost ? $filters.format_money(data.avg_haul_cost) : '' }}</td>
	                        <td class="!border-gray-200">{{ data.rate_calculation }}</td>
							<td class="!border-gray-200">{{ $filters.roundWithPrecision(data.hauls_recorded, 2)?.toLocaleString() }}</td>
							<td class="!border-gray-200">{{ $filters.roundWithPrecision(data.tons_disposed, 2)?.toLocaleString() }}</td>
	                        <td class="!border-gray-200">{{ data.weight_measurement }}</td>
							<td class="!border-gray-200">{{ data.avg_tons_per_haul ? $filters.roundWithPrecision(data.avg_tons_per_haul, 2)?.toLocaleString() : 'N/A' }}</td>
							<td class="!border-gray-200">{{ $filters.roundWithPrecision(data.hauls_eliminated, 2)?.toLocaleString() }}</td>
							<td class="!border-gray-200">{{ $filters.roundWithPrecision(data.ghg_eliminated, 2)?.toLocaleString() }}</td>
							<td class="!border-gray-200">
								<span
									:class="{
									'bg-red-200 rounded rounded-full p-2': parseAndSign(data.gross_savings_amount?.amount) === -1,
									'bg-green-200 rounded rounded-full p-2': parseAndSign(data.gross_savings_amount?.amount) === 1}"
								>
									{{ !data.gross_savings_amount || parseAndSign(data.gross_savings_amount.amount) === 0 ? '' : $filters.format_money(data.gross_savings_amount) }}</span>
							</td>
							<td class="!border-gray-200">{{ $filters.roundWithPrecision(data.gross_savings_percentage, 2) }}</td>
						</tr>
						</tbody>
					</table>
				</div>
			</div>
			<pagination-2 :list="reportData" />
			<div v-if="!reportData.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
				<icon name="dumpster" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
				<span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Report Data</span>
			</div>
		</div>
	</div>

    <modal ref="reportBaselineDataModal" class="max-w-2xl p-4">
        <template #modal-header="{close}" >
	        <div class="p-4 flex justify-between relative">
		        <div class="my-1 text-2xl px-2">
			        Baseline Data
		        </div>
		        <button type="button" class="focus:outline-none absolute ml-6 top-3 right-3" aria-label="Close" @click="close">
			        <icon name="times" class="fill-current inline w-6 h-6" />
		        </button>
	        </div>
        </template>

        <template #modal-body>
	        <div class="col-span-2 m-2 max-width mx-auto">
		        <div class=" justify-center items-center h-full w-full px-4">
			        <div class="form-label text-lg m-2 flex">
				        Pioneer Generated
				        <tooltip-icon class="text-right" tooltip="These are the original pre-monitor values generated by Pioneer" />
			        </div>
			        <div class="border-2 rounded grid grid-cols-2 p-4 mx-auto max-width relative">
				        <div class="col-span-2 grid grid-cols-2 text-left">
					        <div class="text-sm grid">
						        <div class="font-semibold text-d-orange-500">Avg Hauls per Month</div>
						        <div>
							        {{ preMonitorValues.avg_hauls_per_month }}
						        </div>
					        </div>
					        <div class="text-sm grid px-2">
						        <div class="font-semibold text-d-orange-500">Avg Tons per Month</div>
						        <div>
							        {{ preMonitorValues.avg_tons_per_month }}
						        </div>
					        </div>

				        </div>
				        <div class="text-md font-semibold end-1 bottom-1 absolute">
				        </div>
			        </div>
		        </div>
	        </div>
            <form @submit.prevent="submitBaselineDataForm" id="baseline-data-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 mb-6 relative mx-1">
	            <div class="form-label text-lg mx-4 pt-5 flex">
		            Override
		            <tooltip-icon class="text-right" tooltip="Overriding Pioneer generated values will recalculate reports" />
	            </div>
	            <div class="grid gap-x-4 grid-cols-1 md:grid-cols-2 mx-4">
		            <text-input type="number" class="col-span-2 md:col-span-1 form-input-with-buttons" min="1" step="0.001" v-model="baselineDataForm.pre_monitor_avg_hauls_per_month" label="Avg Hauls Per Month"/>
		            <text-input type="number" class="col-span-2 md:col-span-1 form-input-with-buttons" min="1" step="0.001" v-model="baselineDataForm.pre_monitor_avg_tons_per_month" label="Avg Tons Per Month" />
	            </div>

	            <div class="px-4 relative m-1 text-right flex">
		            <loading-button v-if="baselineDataValuesAreModified" type="button" :disabled="!baselineDataValuesAreModified" class="btn btn-gray mr-2 text-lg md:text-sm" @click="revertBaseLineData">
			            Revert To Pioneer Generated Values
		            </loading-button>
		            <loading-button type="submit" class="btn btn-orange end-0 text-lg md:text-sm">Save</loading-button>
	            </div>
            </form>
        </template>

	    <template #modal-footer="{close}">
			<span class="sr-only"></span>
	    </template>
    </modal>

	<slide-over ref="reportFilterSlideOver">
		<template #header="{close}">
			<div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
				<div class="flex items-start justify-between">
					<h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
					<div class="ml-3 flex h-7 items-center">
						<button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
							<span class="sr-only">Close panel</span>
							<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
								<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>

				</div>
			</div>
		</template>

		<template #body>
			<form @submit.prevent id="monthly-impact-summaries-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
				<div class="col-span-6 sm:col-span-1">
					<select-input-2 id="start_date" name="start_date" :options="months" class="col-span-6 sm:col-span-1 mb-5" v-model="form.start_date" label="Start Date">
						<template #option-display="{option, selected}">
							<div :class="{'font-semibold': selected}" class="block">
								<div>{{ option }}</div>
							</div>
							<span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                            </span>
						</template>
					</select-input-2>

                    <select-input-2 id="end_date" name="end_date" :options="months" class="col-span-6 sm:col-span-1" v-model="form.end_date" label="End Date">
                        <template #option-display="{option, selected}">
                            <div :class="{'font-semibold': selected}" class="block">
                                <div>{{ option }}</div>
                            </div>
                            <span v-if="selected" class="absolute inset-y-0 right-0 flex items-center pr-4 text-blue-600">
                                <icon name="check" class="h-5 w-5 inline fill-current"></icon>
                            </span>
                        </template>
                    </select-input-2>
				</div>
			</form>
		</template>
	</slide-over>

</template>

<style>
.form-input-with-buttons input::-webkit-outer-spin-button{
	display: block;
}

.form-input-with-buttons input::-webkit-inner-spin-button {
	display: block;
}
</style>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from "@/Shared/SlideOver.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';
    import {filters as $filters} from "../../../Shared/Utils/Filters";
    import Modal from "../../../Shared/Modal.vue";
    import TextInput from "../../../Shared/TextInput.vue";
    import SelectInput2 from "../../../Shared/SelectInput2.vue";
    import TooltipIcon from "../../../Shared/TooltipIcon.vue";
    import SubNav from "../Show/SubNav.vue";
    import CompactorMonitorHeader from "../Show/CompactorMonitorHeader.vue";
    import CompactorMonitorWorkOrderTonnageColumnChart from "../../../Shared/Reports/CompactorMonitorWorkOrderTonnageColumnChart.vue";
    import CompactorMonitorReportingDashboardBarChart from "../../../Shared/Reports/CompactorMonitors/Dashboard/CompactorMonitorReportingDashboardBarChart.vue";
    import Pagination2 from "../../../Shared/Pagination2.vue";

    export default {
        components: {
	        Pagination2,
	        CompactorMonitorReportingDashboardBarChart,
	        CompactorMonitorWorkOrderTonnageColumnChart,
	        CompactorMonitorHeader,
	        SubNav,
	        TooltipIcon,
	        SelectInput2,
	        TextInput,
	        Modal,
            DateInput,
            DropdownLink,
            Icon,
            LoadingButton,
            ReportingTable,
            SelectInput,
            Head,
            SlideOver,

            // Tailwind UI combobox.
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

	        allReportData: {
				type: Object,
		        required: true
	        },

            compactorMonitor: {
                type: Object,
                required: true
            },

	        preMonitorValues: {
		        type: Object,
		        required: true
	        },

	        months: {
				type: Array,
                required: true
	        },

            filters: Object,

	        workOrders: {
		        type: Array,
		        required: true
	        },

	        reportSummary: {
		        type: Object,
		        required: true
	        }
        },

        data() {
            return {
                form: {
                    start_date: this.filters.start_date,
                    end_date: this.filters.end_date,
                },
	            baselineDataForm: {
		            pre_monitor_avg_tons_per_month: this.compactorMonitor?.monitorConfiguration?.pre_monitor_avg_tons_per_month,
		            pre_monitor_avg_hauls_per_month: this.compactorMonitor?.monitorConfiguration?.pre_monitor_avg_hauls_per_month,
	            },
                saving: false,
                state: 'passive',
                mounted: false,
                filtersInUse: 0,
	            redrawCharts: 0,
            }
        },

        mounted() {
	        Echo.join(`monthly_impact_summaries.${this.compactorMonitor.id}`)
		        .listen('.App\\Events\\CompactorMonitor\\CreateMonthlyImpactSummariesStarted', (e) => {
			        this.$toast.success('Recreating Monthly Impact Summaries. This page will automatically refresh when the process is complete.');
		        })
		        .listen('.App\\Events\\CompactorMonitor\\CreateMonthlyImpactSummariesFinished', (e) => {
			        this.$toast.success('Monthly Impact Summaries have been successfully recreated.');
			        window.location.reload();

		        });
            this.mounted = true;

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
	        parseAndSign (amount) {
		        if (!amount) {
					return;
		        }

		        return Math.sign(Number(amount));
	        },

            exportData() {
                this.saving = 'exporting';

                this.$inertia.post(this.$route('csv.compactor-monitors.reports.monthly-impact-summaries', this.compactorMonitor.id), {...this.form}, {
                    onFinish: () => { this.saving = 'passive'; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

	        submitBaselineDataForm() {
			        this.$inertia.post(this.$route('compactor-monitor-configurations.update-pre-monitor-values', this.compactorMonitor.monitorConfiguration.id), this.baselineDataForm, {
			        preserveState: true,
			        onSuccess: () => {
				        this.$refs.reportBaselineDataModal.close();
			        }
		        });
	        },

	        revertBaseLineData() {
		        if (window.confirm('Are you sure you want to revert to the system generated values?')) {
			        this.baselineDataForm.pre_monitor_avg_tons_per_month = null;
			        this.baselineDataForm.pre_monitor_avg_hauls_per_month = null;
			        return this.submitBaselineDataForm();
		        }
	        },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route("compactor-monitors.reports.monthly-impact-summaries", this.compactorMonitor.id), this.queryFilters, {
								// preserveState: true
	                            onSuccess: () => {
		                            this.redrawCharts++;
	                            }
							});
                            this.filtersInUse = this.getFiltersUsed;

                        }
                    }, 150),
                    {deep: true}
                );
            },

            pickupDetailsUrl(month) {
                return this.$route('compactor-monitors.reports.pickup-details', {
                    monitorId: month.compactor_monitor_id,
                    start_date: month.start_date,
                    end_date: month.end_date,
                });
            },

	        getGrossSpendSeries() {
		        return [
			        {
				        name: 'Before Pioneer',
				        value: this.reportSummary.withoutMonitor?.gross_spend_amount_sum_money ? parseInt(this.reportSummary.withoutMonitor.gross_spend_amount_sum_money.amount) / 100 : null
			        },
			        {
				        name: 'With Pioneer',
				        value: this.reportSummary.data?.gross_spend_amount_sum_money ? parseInt(this.reportSummary.data.gross_spend_amount_sum_money.amount) / 100 : null
			        }
		        ];
	        },

	        getHaulsRecordedSeries() {
		        return [
			        {
				        name: 'Before Pioneer',
				        value: this.reportSummary.withoutMonitor?.hauls_recorded_sum
			        },
			        {
				        name: 'With Pioneer',
				        value: this.reportSummary.data?.hauls_recorded_sum
			        }
		        ];
	        },

	        getGHGEmissionsSeries() {
		        return [
			        {
				        name: 'Before Pioneer',
				        value: this.reportSummary.withoutMonitor?.ghg_emissions
			        },
			        {
				        name: 'With Pioneer',
				        value: this.reportSummary.data?.ghg_emissions
			        }
		        ];
	        },

	        getPickupWeightSeries() {
		        return [
			        {
				        name: 'Before Pioneer',
				        value: this.reportSummary.withoutMonitor?.avg_tons_per_haul
			        },
			        {
				        name: 'With Pioneer',
				        value: this.reportSummary.data?.avg_tons_per_haul
			        }
		        ];
	        }
        },

        computed: {
	        $filters() {
		        return $filters
	        },
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

	        baselineDataValuesAreModified() {
		        return !!(this.compactorMonitor.monitorConfiguration?.pre_monitor_avg_hauls_per_month ||
		        this.compactorMonitor.monitorConfiguration?.pre_monitor_avg_tons_per_month);
	        },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
					form.start_date,
                    form.end_date
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        },
    }
</script>