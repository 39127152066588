<template>
	<tr @click.prevent.stop="navigate" class="hover:bg-gray-100 cursor-pointer rounded">
		<slot>

		</slot>
	</tr>
</template>

<script>
export default {
	props: {
		hrefBase: {
			type: String,
			required: true,
		},

		id: {
			required: true,
		}
	},

	methods: {
		navigate(event) {
			if (event.ctrlKey) {
				switch (event.target.nodeName) {
					case 'A':
						const { href } = event.target;
						window.open(href, '_blank');
						break;
					case 'svg':
						break;
					case 'BUTTON':
						break;
					case 'path':
						break;
					default:
						window.open(`${window.location.origin}/${this.hrefBase}/${this.id}`, '_blank');
				}
			} else {
				switch (event.target.nodeName) {
					case 'A':
						const { href } = event.target;
						this.$inertia.visit(href);
						break;
					case 'svg':
						break;
					case 'BUTTON':
						break;
					case 'path':
						break;
					default:
						this.$inertia.visit(`${this.hrefBase}/${this.id}`);
				}
			}
		}
	}
}
</script>