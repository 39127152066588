<template>
	<Head :title="`Compactor Monitor - ${compactorMonitor.id}`"/>

	<Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
		<nav class="breadcrumbs">
			<inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

			<icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

			<inertia-link :href="$route('compactor-monitors.index')" class="breadcrumb-link">Compactor Monitors
			</inertia-link>

			<icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

			<span>{{ compactorMonitor.monitoredService?.location.name ?? compactorMonitor.id }}</span>
		</nav>
	</Teleport>

    <!-- Page header -->
	<div class="lg:sticky lg:top-0 lg:bg-white lg:z-20">
		<CompactorMonitorHeader :compactor-monitor="compactorMonitor" />
		<SubNav :compactor-monitor="compactorMonitor" current-tab="maintenance" />
	</div>

    <div class="max-w-7xl mx-auto my-3 3xl:max-w-screen-2xl 4xl:max-w-none">
	    <div class="grid grid-cols-1 lg:grid-cols-6 lg:grid-flow-col-dense gap-6 mx-2 md:mx-3 lg:mx-4">
	        <div class="col-span-2 lg:order-2">
		        <section class="lg:col-start-1 lg:col-span-3">
			        <section aria-labelledby="configuration-title">
				        <div class=" bg-white shadow sm:rounded-lg">
					        <div class="border-gray-200 px-4 py-5 sm:px-6">
						        <dl v-if="compactorMonitor.monitorConfiguration"
						            class="grid grid-cols-2 lg:grid-cols-1 gap-x-4 gap-y-8 ">
							        <div class="col-span-2 text-xl flex">Preventative Maintenance
								        <icon name="power-off"
								              class="h-4 w-4 mt-2 ml-2"
								              :content="compactorMonitor.monitorConfiguration.create_preventative_maintenance_work_orders ? 'Preventative Maintenance Scheduling is on!' : 'Preventative Maintenance Scheduling is off!'"
								              :class="[compactorMonitor.monitorConfiguration.create_preventative_maintenance_work_orders ? 'fill-green-700 fill-current' : 'fill-red-700 fill-current']"
								              v-tippy
								        />
							        </div>
							        <div class="lg:col-span-2">
								        <dt class="text-sm font-medium text-gray-500">Most Recent Preventative Maintenance</dt>
								        <dd class="mt-1 text-sm text-gray-900">{{ $filters.format_date(compactorMonitor.monitorConfiguration.latest_preventative_maintenance_occurred_on) }}</dd>
							        </div>
							        <div class="lg:col-span-2">
								        <dt class="text-sm font-medium text-gray-500">Request Preventative Maintenance After (cycles)</dt>
								        <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitor.monitorConfiguration.preventative_maintenance_cycle_count_threshold }}</dd>
							        </div>

							        <div class="lg:col-span-2">
								        <dt class="text-sm font-medium text-gray-500">Average Cycle Time</dt>
								        <dd class="mt-1 text-sm text-gray-900">{{ compactorMonitor.monitorConfiguration.average_time_per_cycle }}</dd>
							        </div>
						        </dl>
						        <div v-else class="text-center">
							        <icon name="cogs" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>
							        <h3 class="mt-2 text-sm font-medium text-gray-900">No configuration</h3>
						        </div>
					        </div>
				        </div>
			        </section>
		        </section>
	        </div>
	        <div class="lg:col-span-4 lg:order-1">
	            <!-- Average Weekly Runs !-->
	            <div class="lg:col-start-1 lg:col-span-3">
	                <section aria-labelledby="average-weekly-runs-title">
	                    <div class="bg-white shadow sm:rounded-lg">
	                        <div class="flex flex-wrap justify-between">
	                            <div class="px-4 py-5 sm:px-6">
	                                <h2 id="average-weekly-runs-title" class="text-xl leading-6 font-medium text-gray-900">
	                                    Average Weekly Runs</h2>
	                                <p class="mt-1 max-w-2xl text-sm text-gray-500">See when your compactor runs the
	                                    most.</p>
	                            </div>
	                        </div>

	                        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
	                            <compactor-monitor-runs-heatmap :runs-series="runsSeries"/>
	                        </div>
	                    </div>
	                </section>
	            </div>

		        <!-- Vitals Chart !-->
		        <div class="space-y-6 mt-3 lg:col-start-1 lg:col-span-3">
			        <section aria-labelledby="vitals-title">
				        <div class="bg-white shadow sm:rounded-lg">
					        <div class="flex flex-wrap justify-between">
						        <div class="px-4 py-5 sm:px-6">
							        <h2 id="fullness-title" class="text-xl leading-6 font-medium text-gray-900">Signal Information</h2>
							        <p class="mt-1 max-w-2xl text-sm text-gray-500"></p>
						        </div>
						        <div class="px-4 py-4 sm:px-6">
							        <loading-button class="btn btn-gray justify-center" @click="refreshDeviceInfoAndVitals"
							                        :loading="refreshingVitals">{{ refreshingVitals ? 'Refreshing...' : 'Refresh' }}
							        </loading-button>
						        </div>
					        </div>
					        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
						        <div v-if="signalQuality?.length">
							        <particle-vitals-area-chart
								        :signal-quality="signalQuality"
								        :signal-strength="signalStrength"
								        :fetching="fetchingVitals"
								        :refreshing="refreshingVitals"
							        />
						        </div>
						        <div v-else class="px-4 py-4 grid items-center text-2xl font-semibold"
						             style="height: 400px; background-image: url('/images/area_graph_no_data_bw.png')">
							        <div class="text-center">
								        <template v-if="!signalQuality">
									        Fetching data. Please wait...
								        </template>

								        <template v-else>
									        No vitals available. Please refresh vitals.
								        </template>
							        </div>
						        </div>
					        </div>
				        </div>
			        </section>
		        </div>

				<!-- Preventative Maintenance Work Orders-->
		        <div class="space-y-6 mt-3 lg:col-start-1 lg:col-span-3">
			        <section aria-labelledby="pickups-title">
				        <div class="bg-white shadow sm:rounded-lg">
					        <div class="flex flex-wrap justify-between">
						        <div class="px-4 py-5 sm:px-6">
							        <h2 id="pickups-title" class="text-xl leading-6 font-medium text-gray-900">Maintenance Work Orders</h2>
						        </div>
					        </div>

					        <div class="border-t border-gray-200 px-4 py-5 sm:px-6">
						        <div v-if="maintenanceWorkOrders.length" class="flex flex-col">
							        <div class="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
								        <div class="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
									        <table class="table table-condensed">
										        <thead>
										        <tr>
											        <th scope="col"
											            class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
												        Date
											        </th>
											        <th scope="col"
											            class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
												        Status
											        </th>
											        <th scope="col"
											            class="whitespace-nowrap px-2 py-3.5 text-left text-sm text-gray-900">
												        Tonnage
											        </th>
											        <th scope="col"
											            class="relative whitespace-nowrap py-3.5 pl-3 pr-4 sm:pr-6">
												        <span class="sr-only">Edit</span>
											        </th>
										        </tr>
										        </thead>

										        <tbody class="divide-y divide-gray-200 bg-white">
										        <tr v-for="maintenanceWorkOrder in maintenanceWorkOrders" :key="maintenanceWorkOrder.id">
											        <td>{{
													        $filters.format_date(maintenanceWorkOrder.most_accurate_event_date)
												        }}
											        </td>
											        <td>{{ maintenanceWorkOrder.decorated_status }}</td>
											        <td>{{ maintenanceWorkOrder.confirmed_quantity }}</td>
											        <td class="relative whitespace-nowrap py-2 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
												        <inertia-link :href="$route('work-orders.edit', maintenanceWorkOrder.id)"
												                      class="link">
													        Edit<span class="sr-only">, {{ maintenanceWorkOrder.id }}</span>
												        </inertia-link>
											        </td>
										        </tr>
										        </tbody>
									        </table>
								        </div>
							        </div>
						        </div>

						        <div v-else class="text-center">
							        <icon name="clipboard" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>

							        <h3 class="mt-2 text-sm font-medium text-gray-900">No Maintenance Work Orders</h3>
						        </div>
					        </div>

					        <div v-if="maintenanceWorkOrders.length">
						        <inertia-link
							        :href="$route('work-orders.index', {search: compactorMonitor.monitored_service_id})"
							        class="block bg-gray-50 text-sm font-medium text-gray-500 text-center px-4 py-4 hover:text-gray-700 sm:rounded-b-lg">
							        View All
						        </inertia-link>
					        </div>
				        </div>
			        </section>
		        </div>
	        </div>
	    </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import LoadingButton from "../../../Shared/LoadingButton.vue";
    import CompactorMonitorFullnessColumnChart from '@/Shared/Reports/CompactorMonitorFullnessColumnChart.vue';
    import CompactorMonitorRunsHeatmap from '@/Shared/Reports/CompactorMonitorRunsHeatmap.vue';
    import ParticleVitalsAreaChart from "../../../Shared/Reports/ParticleVitalsAreaChart.vue";
    import { differenceInCalendarDays, format, parseISO, fromUnixTime, differenceInDays } from 'date-fns';
    import { debounce } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import axios from "axios";
    import CompactorMonitorHeader from "./CompactorMonitorHeader.vue";
    import SubNav from "./SubNav.vue";

export default {
    components: {
	    SubNav,
	    CompactorMonitorHeader,
        ParticleVitalsAreaChart,
        LoadingButton,
        Icon,
        DateInput,
        CompactorMonitorFullnessColumnChart,
        CompactorMonitorRunsHeatmap,
        Head,
    },

    props: {
        errors: {
            type: Object,
            default: () => ({})
        },

        compactorMonitor: {
            type: Object,
            required: true
        },

        runsSeries: {
            type: Array,
            required: true
        },

	    maintenanceWorkOrders: {
		    type: Array,
		    required: false
	    },
    },

    data() {
        return {
            form: {
                begin_date: null,
                end_date: format(Date.now(), 'yyyy-MM-dd'),
            },
            fetchingVitals: false,
            mounted: false,
            signaling: false,
            refreshingVitals: false,
            pickups: null,
            deviceInfoAndVitalsRefreshed: 0,
            signalStrength: null,
            signalQuality: null,
	        statuses: {
		        'online': 'animate-pulse text-green-400 bg-green-400/20',
		        'offline': 'animate-pulse text-rose-400 bg-rose-400/20',
		        'inactive': 'text-gray-400 bg-gray-400/20',
	        },
        }
    },

    mounted() {
	    this.mounted = true;
        Echo.join(`compactor_monitors.${this.compactorMonitor.id}`)
            .listen('.App\\Events\\CompactorMonitor\\FetchParticleInfoAndVitalsJobStarted', (e) => {
                this.refreshingVitals = true;
            })
            .listen('.App\\Events\\CompactorMonitor\\FetchParticleInfoAndVitalsJobFinished', (e) => {
                this.$toast.success('Device info and vitals refreshed!');
                this.getDeviceVitalsForRange();
                this.deviceInfoAndVitalsRefreshed++;
                this.refreshingVitals = false;
            })
            .listen('.App\\Events\\CompactorMonitor\\FetchParticleInfoAndVitalsJobFailed', (e) => {
                this.$toast.error('Failed to refresh device info and vitals.');
                this.refreshingVitals = false;
            })

        this.form.begin_date = this.threeMonthsAgo();
        this.getDeviceVitalsForRange();
    },

    unmounted() {
        Echo.leave(`compactor_monitors.${this.compactorMonitor.id}`);
    },


    methods: {
        threeMonthsAgo() {
            const now = new Date();
            // Adjust the following to use the start of the month:
            const threeMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate()).setDate(1);

            return format(threeMonthsAgo, 'yyyy-MM-dd');
        },

        refreshDeviceInfoAndVitals() {
            this.refreshingVitals = true;

            this.$inertia.post(this.$route('compactor-monitors.refresh-device-info-and-vitals', this.compactorMonitor.id), {},{
                preserveState: true,
                preserveScroll: true,
            })
        },

        getDeviceVitalsForRange() {
            this.fetchingVitals = true;
            axios.get(this.$route('particle-device-vitals', this.compactorMonitor.id), {}).then(response => {
                this.signalQuality = response.data.signalQuality;
                this.signalStrength = response.data.signalStrength;
                this.fetchingVitals = false;
            })
                .catch(error => {
                this.$toast.error('Failed to fetch device vitals.');
            });
        },
    },
}
</script>