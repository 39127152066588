<template>
    <div v-bind="$attrs">
        <div :class="tabs.length > 2 ? 'block md:hidden w-full mx-auto sm:px-6 md:flex md:items-center md:grid md:grid-cols-3 lg:px-1' : 'hidden'">
            <aside class="gap-y-8 pt-6 lg:col-span-3 mb-3">
                <nav>
                    <button @click="$refs.navigationSlideOver.show()" class="flex justify-center items-center btn btn-gray w-full text-xl p-3">
                        <span class="text-d-orange-500"> {{ capitalize(currentTab) }}</span>
                        <icon name="menu" class="h-6 w-6 ml-4 text-d-orange-500 fill-current" @click="$refs.navigationSlideOver.show()"></icon>
                    </button>
                </nav>
            </aside>
        </div>

        <div :class="tabs.length > 2 ? 'hidden md:block' : 'block'">
            <div class="border-b border-gray-300">
                <nav class="-mb-px flex" aria-label="Tabs">
                    <inertia-link v-for="tab in normalTabs" :key="tab.key" :href="tab.href" class="whitespace-nowrap border-b-[3px] px-6 py-4 font-medium" :class="classBindings(tab.key)" :aria-current="tab.key === currentTab">
                        <span>{{ tab.name }}</span>
                    </inertia-link>

                    <span class="whitespace-nowrap border-b-[3px] px-3 py-2 font-medium"
                          v-for="tab in dropdownTabs"
                          ref="dropdown"
                          :key="tab.key"
                          :class="classBindings(tab.key)"
                          :aria-current="tab.key === currentTab"
                          @click.prevent.stop="toggleReports(tab.key)"
                          @blur="toggleReports(tab.key)"
                    >
                        <dropdown-link>
                            <template #link>
                                <span class="inline-flex items-center space-x-1">
                                    <span>{{ tab.name }}</span>
                                    <icon :name="expandReports === tab.key ? 'chevron-down' : 'chevron-right'" class="size-3 fill-current ml-1" />
                                </span>
                            </template>

                            <template #dropdown>
                                <div class="dropdown w-72 mt-1 -ml-4">
                                    <inertia-link v-for="link in tab.subnav" :key="link.key" :href="link.href" class="dropdown-link">
                                        <span :class="{'border-d-orange-500 text-d-orange-600': currentTab === link.key}">
                                            {{ $filters.capitalize(link.name) }}
                                        </span>
                                    </inertia-link>
                                </div>
                            </template>
                        </dropdown-link>
                    </span>
                </nav>
            </div>
        </div>
    </div>

	<slide-over ref="navigationSlideOver">
		<template #header="{close}">
			<div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
				<div class="flex items-start justify-between">
					<h2 class="text-lg font-medium text-white text-wrap" id="slide-over-title">Compactor Monitors</h2>
					<div class="ml-3 flex h-7 items-center">
						<button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
							<span class="sr-only">Close panel</span>
							<svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
								<path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>
				</div>
			</div>
		</template>

		<template #body>
			<div class="col-span-6 sm:col-span-1 text-left">
				<inertia-link v-for="tab in normalTabs" :key="tab.key" :href="tab.href" class="group py-2 flex items-start text-sm font-medium" :class="classBindings(tab.key)" :aria-current="tab.key === currentTab">
					<span class="text-lg">{{ tab.name }}</span>
				</inertia-link>

				<span class="group py-1 w-auto -space-x-2 flex items-start text-sm font-medium relative -my-1"
                      ref="dropdown"
                      v-for="tab in dropdownTabs"
                      :key="tab.key"
				      :class="classBindings(tab.key)"
				      :aria-current="tab.key === currentTab"
				      @click.prevent.stop="toggleReports(tab.key)"
				      @blur="toggleReports(tab.key)"
				>
		            <dropdown-link>
				        <template #link>
				            <span class="flex items-center text-lg -ml-2">
                                {{ tab.name }}
				                <icon :name="expandReports === tab.key ? 'chevron-down' : 'chevron-right'" class="h-4 w-4 ml-1" />
				            </span>
				        </template>

				        <template #dropdown>
				            <div class="dropdown w-72 text-left mt-3 ">
				                <inertia-link v-for="link in tab.subnav" :key="link.key" :href="link.href" class="dropdown-link">
					                <span :class="{'border-d-orange-500 text-d-orange-500': currentTab === link.key}">
						                {{ link.name }}
					                </span>
				                </inertia-link>
				            </div>
				        </template>
		            </dropdown-link>
	            </span>
			</div>
		</template>
	</slide-over>
</template>

<script setup>
    import Icon from '@/Shared/Icon.vue';
    import DropdownLink from "@/Shared/DropdownLink.vue";
    import SlideOver from "@/Shared/SlideOver.vue";

    import {computed, onBeforeUnmount, onMounted, ref, toRaw} from "vue";
    import {capitalize} from "lodash-es";

    const props = defineProps({
        currentTab: {
            type: String,
            required: true
        },
        tabs: {
            type: Array,
            default: () => []
        }
    });

    const dropdown = ref(null);

    const mounted = ref(false);
    const expandReports = ref(null);

    const normalTabs = computed(() => {
        return props.tabs.filter(tab => tab.href !== '');
    });
    const dropdownTabs = computed(() =>{
        return props.tabs.filter(tab => tab.href === '');
    });

    onMounted(() => {
        document.addEventListener('click', clickOutsideHandler);
        mounted.value = true;
    });

    onBeforeUnmount(() => {
        document.removeEventListener('click', clickOutsideHandler);
    });

    function toggleReports(key) {
        if(expandReports.value === key) {
            expandReports.value = null;
            return;
        }

        expandReports.value = key;
    }

    function classBindings(tabKey) {
        return tabKey === props.currentTab || props.tabs.filter(tab => tab.subnav)?.find(tab => tab.key === tabKey)?.subnav.find(link => link.key === props.currentTab)
            ? 'border-d-orange-500 text-d-orange-600'
            : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700';
    }

    function clickOutsideHandler(e) {
        if (!dropdown.value.some((el) => el.contains(e.target)) && expandReports.value) {
            expandReports.value = null;
        }
    }
</script>