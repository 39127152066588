<template>
    <div v-bind="$attrs">
        <Popover class="hidden relative mr-2 md:block">
            <PopoverButton class="btn btn-gray inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 hover:bg-gray-50">
                Columns
                <icon class="w-4 h-4 shrink-0 inline fill-current" name="chevron-down" />
            </PopoverButton>

            <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
            >
                <PopoverPanel class="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div class="divide-y divide-gray-200 border-b border-t border-gray-200">
                        <table class="table">
                            <thead>
                            <tr>
                                <input type="checkbox" class="m-4 rounded border-gray-300 text-d-orange-600 focus:ring-d-orange-500" :checked="indeterminateColumns || value.length === headers.length" :indeterminate="indeterminateColumns" @change="value = $event.target.checked ? headers : excludedHeaders" />
                                <th>All</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="header in allowedHeaders" :key="header">
                                <input type="checkbox" class="m-4 rounded border-gray-300 text-d-orange-600 focus:ring-d-orange-500" :value="header" v-model="value" />
                                <td>{{ header }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </PopoverPanel>
            </transition>
        </Popover>

        <div class="relative mr-2 md:hidden">
            <button type="button" class="btn btn-gray inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50" @click="$refs.slideOver.show()">
                Columns
                <icon class="w-4 h-4 shrink-0 inline fill-current" name="chevron-down" />
            </button>

            <slide-over ref="slideOver">
                <template #header="{close}">
                    <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                        <div class="flex items-start justify-between">
                            <h2 class="text-lg font-medium text-white" id="slide-over-title">Show Columns</h2>
                            <div class="ml-3 flex h-7 items-center">
                                <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                                    <span class="sr-only">Close panel</span>
                                    <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                </template>

                <template #body>
                    <div class="divide-y divide-gray-200 border-b border-t border-gray-200">
                        <table class="table">
                            <thead>
                            <tr>
                                <input type="checkbox" class="m-4 rounded border-gray-300 text-d-orange-600 focus:ring-d-orange-500" :checked="indeterminateColumns || value.length === headers.length" :indeterminate="indeterminateColumns" @change="value = $event.target.checked ? headers : excludedHeaders" />
                                <th>All</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="header in allowedHeaders" :key="header">
                                <input type="checkbox" class="m-4 rounded border-gray-300 text-d-orange-600 focus:ring-d-orange-500" :value="header" v-model="value" />
                                <td>{{ header }}</td>
                            </tr>
                            </tbody>
                        </table>
                    </div>
                </template>
            </slide-over>
        </div>
    </div>
</template>

<script setup>
    import { computed } from 'vue';
    import Icon from '@/Shared/Icon.vue';
    import SlideOver from "@/Shared/SlideOver.vue";
    import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
   
    const emit = defineEmits(['update:modelValue', 'opened', 'closed']);

    defineOptions({
        inheritAttrs: false,
    });

    const props = defineProps({
        headers: {
            type: Array,
            required: true,
        },

        excludedHeaders: {
            type: Array,
            required: true,
        },

        modelValue: {
            type: Array,
            required: true,
        },
    });

    const indeterminateColumns = computed(() => {
        return value.value.length > props.excludedHeaders.length && value.value.length < props.headers.length;
    });

    const allowedHeaders = computed(() => {
        return props.headers.filter(header => !props.excludedHeaders.includes(header));
    });

    const value = computed({
        get() {
            return props.modelValue;
        },

        set(value) {
            emit('update:modelValue', value);
        },
    });
</script>