<template>
	<div class="hidden md:flex md:items-center md:justify-between pt-5">
		<div>
			<p class="text-sm text-gray-700">
				Showing
				<span class="font-medium">{{ list.from }} - {{ list.to}}</span>
				of
				<span class="font-medium">{{ list.total }}</span>
			</p>
		</div>
		<div class="flex whitespace-nowrap">
			<template v-for="(link, key) in links">
				<div v-if="link.url === null"
				     class="mr-1 px-4 py-3 text-sm border bg-gray-50 rounded-lg text-d-blue-300 cursor-not-allowed"
				     v-html="link.label" />

				<inertia-link v-else
				              class="mr-1 px-4 py-3 text-sm border rounded-lg hover:bg-d-blue-500 hover:text-white focus:border-d-blue-500 focus:text-white font-semibold"
				              :class="{ 'bg-d-blue-500': link.active, 'text-white': link.active, 'bg-white': !link.active, 'text-d-blue-500': !link.active }"
				              :href="link.url"
				              v-html="link.label" />
			</template>
		</div>
	</div>
	<div class="md:hidden mt-6 mb-20 w-full">
		<div class="mb-2">
			<p class="text-sm text-gray-700">
				Showing
				<span class="font-medium">{{ list.from }} - {{ list.to}}</span>
				of
				<span class="font-medium">{{ list.total }}</span>
			</p>
		</div>
		<div class="grid grid-cols-2 gap-4">
			<template v-for="(link, key) in links">
				<div v-if="(link.label.includes('Previous') || link.label.includes('Next')) && link.url === null"
				     class=" text-center px-4 py-3 text-lg border rounded-lg bg-d-gray-200 font-semibold cursor-not-allowed"
				     v-html="link.label" />
				<inertia-link v-if="(link.label.includes('Previous') || link.label.includes('Next')) && link.url"
				              class=" text-center px-4 py-3 text-lg border rounded-lg hover:bg-d-blue-500 hover:text-white focus:border-d-blue-500 focus:text-white font-semibold"
				              :class="{ 'bg-d-blue-500': link.active, 'text-white': link.active, 'bg-white': !link.active, 'text-d-blue-500': !link.active, 'bg-gray-50 rounded-lg text-d-blue-300 cursor-not-allowed': !link.url }"
				              :href="link.url"
				              v-html="link.label" disabled/>
			</template>
		</div>
	</div>
</template>

<script setup>
import { ref } from 'vue';

const props = defineProps({
    list: {
        type: Object,
        required: true,
    }
});

const links = ref(props.list.links);
</script>
