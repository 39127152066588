<template>
    <!-- Page header -->
    <div v-bind="$attrs" class="mx-auto pb-6 md:pb-0 md:flex">
	    <div class="hidden mx-auto md:block md:grid md:grid-cols-4 md:divide-x lg:grid-cols-5 shadow py-3 items-center w-full">
		    <div class="hidden p-3 lg:flex md:items-center md:justify-center md:col-span-2 lg:col-span-1">
                <logo name="pioneer-full-dark" class="hidden lg:block w-auto px-4" />
                <logo name="pioneer-full-single-dark" class="block lg:hidden h-16" />
		    </div>
		    <div class="flex flex-col p-3 md:col-span-2 lg:col-span-1 relative">
			    <tooltip-icon class="absolute top-2 right-2" tooltip="Total number of Pioneer devices that are assigned"/>
			    <div class="px-2 font-medium text-md text-gray-500">
				    <span>Assigned Devices</span>
			    </div>
			    <div class="flex flex-col px-2 py-6 rounded space-y-2 ">
				    <div class="text-3xl font-semibold tracking-tight text-gray-900">
					    <span>{{ fetchingInfo ? 'Fetching...' : assigned}}</span>
				    </div>
				    <div class="text-sm tracking-tight text-gray-500">
					    <span>{{ fetchingInfo ? 'Fetching...' : `of ${totalMonitors} available devices` }}</span>
				    </div>
			    </div>
		    </div>
		    <div class="flex flex-col p-3 md:col-span-2 lg:col-span-1">
			    <div class="px-2 font-medium text-md text-gray-500" style="height:1.5em; overflow:visible">
				    <span>Predictive Pickup Scheduling</span>
			    </div>
			    <div class="px-2 py-6 rounded space-y-2">
				    <div class="text-3xl font-semibold tracking-tight text-gray-900">
					    <span>{{ fetchingInfo ? 'Fetching...' : predictivePickupSchedulingEnabledMonitors}} </span>
				    </div>
				    <div class="text-sm tracking-tight text-gray-500">
					    <span>{{ fetchingInfo ? 'Fetching...' : `of ${assigned} assigned devices` }} </span>
				    </div>
			    </div>
		    </div>
		    <div class="flex flex-col p-3 md:col-span-2 lg:col-span-1 relative">
			    <tooltip-icon class="absolute top-2 right-2" tooltip="Total number of Pioneer devices that have Preventative Maintenance Scheduling enabled"/>
			    <div class="px-2 mt-0 font-medium text-md text-gray-500">
				    <span>PM Scheduling</span>
			    </div>
			    <div class="px-2 py-6 rounded space-y-2 ">
				    <div class="text-3xl font-semibold tracking-tight text-gray-900">
					    <span>{{ fetchingInfo ? 'Fetching...' : preventiveMaintenanceSchedulingEnabledMonitors}}</span>
				    </div>
				    <div class="text-sm tracking-tight text-gray-500">
					    <span>{{ fetchingInfo ? 'Fetching...' : `of ${assigned} assigned devices` }}</span>
				    </div>
			    </div>
		    </div>
		    <div class="flex flex-col p-3 md:col-span-2 lg:col-span-1 relative">
			    <tooltip-icon class="absolute top-2 right-2" tooltip="Total number of Pioneer devices that have alerts" />
			    <div class="px-2 mt-0 font-medium text-md text-gray-500">
				    <span class="text-lg">Need Attention</span>
			    </div>
			    <div class="px-2 py-6">
				    <inertia-link
					    :href="$route('compactor-monitors.index', {'needs_attention' : 'only'})"
				    >
					    <div class="text-3xl font-semibold tracking-tight text-gray-900 inline-flex space-x-2 mb-2">
						    <icon v-if="needsAttention" name="exclamation-triangle" class="text-rose-400 fill-current h-7 w-7 inline pt-1"/>
						    <span>{{  needsAttention ? needsAttention : 'Fetching...'}}</span>
					    </div>
					    <div class="text-sm tracking-tight text-gray-500" style="min-height: 1em;">
						    <span>&nbsp; </span>
					    </div>
				    </inertia-link>
			    </div>
		    </div>
	    </div>

        <!-- Mobile view header-->
	    <div class="block md:hidden">
		    <div class="grid grid-cols-3">
			    <div class="col-span-1 flex items-center justify-center">
				    <logo name="pioneer-full-single-dark" class="w-16" />
			    </div>
                <div class="col-span-2 flex flex-col p-3">
                    <div class="flex flex-1 flex-col px-4 py-6 rounded " :class="{ 'bg-gray-200/40' : !needsAttention, 'bg-rose-400/20' : needsAttention > 0, 'bg-green-400/20' : needsAttention === 0, }">
                        <inertia-link class="flex flex-col flex-grow items-center justify-center" :href="$route('compactor-monitors.index', {'needs_attention' : 'only'})" >
                            <div class="flex leading-5 font-medium text-gray-500">
                                <span class="text-lg">Need Attention</span>
                            </div>
                            <div class="mt-1 text-3xl font-semibold tracking-tight text-gray-900 inline-flex items-baseline space-x-2">
                                <icon v-if="needsAttention" name="exclamation-triangle" class="text-rose-400 fill-current h-6 w-6"/>
                                <span class="">
                                    {{  needsAttention ? needsAttention : 'Fetching...'}}
                                </span>
                            </div>
                        </inertia-link>
                    </div>
                </div>
		    </div>
	    </div>
    </div>
</template>

<script setup>
import {ref, onMounted, inject} from 'vue';
import axios from 'axios';
import Icon from '@/Shared/Icon.vue';
import Logo from "../../../Shared/Logo.vue";
import TooltipIcon from "../../../Shared/TooltipIcon.vue";

let errors = ref({});
let mounted = ref(false);
let needsAttention = ref(null);
let unassigned = ref(null);
let assigned = ref(null);
let totalMonitors = ref(null);
let predictivePickupSchedulingEnabledMonitors = ref(null);
let preventiveMaintenanceSchedulingEnabledMonitors = ref(null);
let fetchingInfo = ref(true);
let fetchingNeedsAttention = ref(true);
let statuses = ref({
	'online': 'animate-pulse text-green-400 bg-green-400/20',
	'offline': 'animate-pulse text-rose-400 bg-rose-400/20',
	'inactive': 'text-gray-400 bg-gray-400/20',
});

const route = inject('route');

const getNeedsAttention = () => {
	axios.get(route('compactor-monitors.needs-attention.index'))
		.then(response => {
			fetchingNeedsAttention.value = false;
			needsAttention.value = response.data.needs_attention;
		})
};

const getInfo = () => {
	axios.get(route('compactor-monitors.info'))
		.then(response => {
			fetchingInfo.value = false;
			totalMonitors.value = response.data.total_compactor_monitors;
			assigned.value = response.data.assigned_compactor_monitors;
			predictivePickupSchedulingEnabledMonitors.value = response.data.predictive_pickup_scheduling_enabled_monitors;
			preventiveMaintenanceSchedulingEnabledMonitors.value = response.data.preventative_maintenance_scheduling_enabled_monitors;
		})
};

onMounted(() => {
	mounted.value = true;
	getNeedsAttention();
	getInfo();
});
</script>