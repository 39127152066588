<template>
    <div class="mt-6 -mb-1 flex flex-wrap">
        <div v-if="links.length > 3">
            <div class="flex flex-wrap -mb-1">
                <template v-for="(link, key) in links">
                    <div v-if="link.url === null"
                        class="mr-1 mb-1 px-4 py-3 text-sm border bg-gray-50 rounded-lg text-d-blue-300 cursor-not-allowed"
                        v-html="link.label" />

                    <inertia-link v-else
                        class="mr-1 mb-1 px-4 py-3 text-sm border rounded-lg hover:bg-d-blue-500 hover:text-white focus:border-d-blue-500 focus:text-white font-semibold"
                        :class="{ 'bg-d-blue-500': link.active, 'text-white': link.active, 'bg-white': !link.active, 'text-d-blue-500': !link.active }"
                        :href="link.url"
                        v-html="link.label" />
                </template>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        links: Array,
    },
}
</script>
