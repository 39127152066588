<template>
    <Head title="Compactor Monitors" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>Compactor Monitors</span>
        </nav>
    </Teleport>

	<div class="max-w-7xl mx-auto 3xl:max-w-screen-2xl 4xl:max-w-none">
		<div class="divide-y md:divide-none">
			<CompactorMonitorIndexHeader />
			<SubNav class="pt-8 md:pt-0 md:mt-8" current-tab="devices" :tabs="subNavTabs" />
		</div>
		<div class="flex flex-fill justify-between items-end gap-y-6">
		    <form @submit.prevent id="compactor-monitor-search-form" class="flex flex-wrap px-3 gap-x-4 gap-y-6 my-4 items-end w-5/6">
		        <div class="flex-grow w-2/5">
		            <label for="search-compactor-monitors" class="form-label">Search</label>

		            <div class="input-group ">
		                <span class="input-group-item">
		                    <icon name="search" class="w-4 h-4 text-gray-400 fill-current mr-2" />
		                </span>

		                <input type="search" name="search-compactor-monitors" id="search-compactor-monitors" v-model="form.search" placeholder="Search" class="input-group-field">
		            </div>
		        </div>

		        <select-input class="min-w-32 flex-grow md:flex-auto" name="per_page" id="per-page" v-model="form.per_page" placeholder="15" label="Results / Page">
		            <option value="25">25</option>
		            <option value="50">50</option>
		            <option value="100">100</option>
		            <option value="200">200</option>
		        </select-input>

	            <div class="flex flex-grow">
	                <div class="flex-auto md:flex-grow">
	                    <div class="flex justify-between md:justify-normal gap-x-2 items-center">
	                        <div v-if="compactorMonitors.data.length" class="md:hidden font-medium text-sm text-gray-700 text-wrap">Showing {{ compactorMonitors.from }} - {{ compactorMonitors.to }} out of {{ compactorMonitors.total }}</div>

	                        <div class="flex">
	                            <button type="button" class="inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 mr-2 text-sm leading-5 rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.searchFilterSlideOver.show()">
	                                <span class="inline-flex px-3 py-2">
	                                    <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
	                                    <span class="hidden md:block ml-2">Filters</span>
	                                </span>

	                                <span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-lg md:text-sm font-semibold text-d-accent-900 rounded-r-md">
	                                    {{ filtersInUse }}
	                                </span>
	                            </button>

	                            <column-selector
	                                class="hidden md:block"
	                                :headers="headers"
	                                :excludedHeaders="excludedHeaders"
	                                v-model="form.selected_headers"
	                            />

	                            <inertia-link :href="$route('compactor-monitors.index', {remember: 'forget'})" class="block btn btn-gray px-3 py-2 text-lg md:text-sm">Clear</inertia-link>
	                        </div>
	                    </div>
	                </div>
	            </div>
		    </form>
			<div class="hidden md:block my-4">
				<loading-button class="btn btn-blue" @click="exportData" :disabled="!compactorMonitors.data.length || extracting" :loading="extracting">
					Export to CSV
				</loading-button>
			</div>
		</div>
	    <div class="hidden md:block max-w-7xl mx-auto md:items-center 3xl:max-w-screen-2xl lg:px-1 4xl:max-w-none relative space-y-6">
	        <div class="overflow-x-auto overflow-y-">
	            <table class="table table-condensed" ref="table">
	                <thead>
	                    <tr>
		                    <th scope="col" class="px-0 text-right text-sm font-semibold text-gray-900 !border-gray-500">
			                    <span v-tippy="'This column displays an icon if there are no runs detected within the past 2 business days.'">
				                    <icon name="info-circle" class="w-4 h-4 fill-current text-gray-400" />
	                                <span class="sr-only">Needs Attention</span>
			                    </span>
		                    </th>
	                        <sortable-table-header field="id" :filters="filters" route-name="compactor-monitors.index" scope="col" class="pr-3 py-3.5 text-left text-sm font-semibold text-gray-900 !border-gray-500">Monitor Id</sortable-table-header>
	                        <sortable-table-header v-show="form.selected_headers.includes('Device Service')" field="device_service_id" :filters="filters" route-name="compactor-monitors.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 !border-gray-500">Device Service</sortable-table-header>
	                        <sortable-table-header v-show="form.selected_headers.includes('Service ID')" field="monitored_service_id" :filters="filters" route-name="compactor-monitors.index" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 !border-gray-500">Service ID</sortable-table-header>
		                    <th v-show="form.selected_headers.includes('Equipment Label')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 wrap !border-gray-500">Equipment Label</th>
	                        <th v-show="form.selected_headers.includes('Location')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 !border-gray-500">Location</th>
		                    <sortable-table-header v-show="form.selected_headers.includes('Current Fullness')" field="percentage_full" :filters="filters" route-name="compactor-monitors.index" scope="col" class="px-3 py-3.5 text-sm font-semibold text-gray-900 !border-gray-500" >Current Fullness</sortable-table-header>
		                    <th v-show="form.selected_headers.includes('Last Pickup')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 !border-gray-500">Last Pickup</th>
	                        <th v-show="form.selected_headers.includes('Next Pickup')" scope="col" class="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 !border-gray-500">Next Pickup</th>
	                        <th v-show="form.selected_headers.includes('Predictive Pickup Scheduling')" scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 !border-gray-500">Predictive Pickup Scheduling</th>
		                    <th v-show="form.selected_headers.includes('PM Scheduling')" scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 relative !border-gray-500" >PM Scheduling</th>
	                        <th scope="col" class="px-3 py-3.5 text-center text-sm font-semibold text-gray-900 transparent !border-gray-500"></th>
	                    </tr>
	                </thead>

	                <tbody>
	                    <clickable-table-row v-for="compactorMonitor in compactorMonitors.data" href-base="compactor-monitors" :id="compactorMonitor.id" :key="compactorMonitor.id">
		                    <td class="!border-gray-200">
			                    <span v-if="needsAttention.includes(compactorMonitor.device_service_id)" class="px-0" v-tippy="'No runs within the past 2 business days!'">
				                     <icon name="exclamation-triangle" class="text-rose-400 fill-current h-4 w-4" />
			                    </span>
		                    </td>
	                        <td class="!border-gray-200">
		                        <div class="flex items-center gap-x-1">
			                        <div :class="[statusClasses[compactorMonitor.connectivity_status], 'flex-none rounded-full p-1']" :content="$filters.capitalize(compactorMonitor.connectivity_status)"  v-tippy>
				                        <div class="h-1.5 w-1.5 rounded-full bg-current" />
			                        </div>
		                            <copy-to-clipboard-button :value="compactorMonitor.id" data-cy="compactor-monitors.index.show-link" @click.stop>
		                                {{ compactorMonitor.id }}
		                            </copy-to-clipboard-button>
		                        </div>
	                        </td>
	                        <td v-show="form.selected_headers.includes('Device Service')" class="!border-gray-200">
	                            <div v-if="compactorMonitor.device_service_id" class="flex items-center gap-x-2">
	                                <inertia-link :href="$route('services.show', compactorMonitor.device_service_id)" class="link" @click.stop>
	                                    {{ compactorMonitor.device_service_id }}
	                                </inertia-link>
	                            </div>

	                            <span v-else>
	                                -
	                            </span>
	                        </td>
	                        <td v-show="form.selected_headers.includes('Service ID')" class="text-left !border-gray-200">
	                            <div v-if="compactorMonitor.monitored_service_id">
	                                <inertia-link :href="$route('services.show', compactorMonitor.monitored_service_id)" class="link" :content="`${compactorMonitor?.monitoredService?.description}`" v-tippy>
	                                    {{ compactorMonitor.monitored_service_id }}
	                                </inertia-link >
	                            </div>

	                            <span v-else>
	                                -
	                            </span>
	                        </td>
		                    <td v-show="form.selected_headers.includes('Equipment Label')" class="wrap !border-gray-200">
			                    <span v-if="compactorMonitor.monitoredService?.label" class="truncate">
				                    {{ compactorMonitor.monitoredService.label }}
	                            </span>
			                    <span v-else>
				                    -
	                            </span>
		                    </td>
	                        <td v-show="form.selected_headers.includes('Location')" class="wrap !border-gray-200">
	                            <inertia-link v-if="compactorMonitor.deviceService" :href="$route('locations.show', compactorMonitor.deviceService.location_id)" class="link">
	                                {{ compactorMonitor.deviceService.location.name }}
	                            </inertia-link>

	                            <span v-else>
	                                -
	                            </span>
	                        </td>
		                    <td v-show="form.selected_headers.includes('Current Fullness')" class="font-semibold !border-gray-200">
	                            <span v-if="compactorMonitor.monitorConfiguration" class="flex">
	                                <span class="mr-2">{{ compactorMonitor.monitorConfiguration?.percentage_full ? compactorMonitor.monitorConfiguration?.percentage_full + '%' : 'N/A' }}</span>
			                        <span class="relative h-5 w-full bg-gray-200 rounded flex items-center">
					                    <span :style="`left:${compactorMonitor.monitorConfiguration.target_percentage_full}%`" class="w-0.5 h-full bg-d-orange-500 absolute items-center" />
					                    <span :style="`width:${compactorMonitor.monitorConfiguration?.percentage_full ?? 0}%`" class="h-full bg-d-cyan-500 rounded transition-all duration-5000 ease-in"/>
				                    </span>
	                            </span>
			                    <span v-else>
	                                -
	                            </span>
		                    </td>
	                        <td v-show="form.selected_headers.includes('Last Pickup')" class="!border-gray-200">
	                            <span v-if="compactorMonitor?.monitorConfiguration?.lastPickupWorkOrder">
	                                <inertia-link :href="$route('work-orders.edit',compactorMonitor.monitorConfiguration.lastPickupWorkOrder.id)" class="link">
	                                    {{ $filters.format_date(compactorMonitor.monitorConfiguration.last_picked_up) }}
	                                </inertia-link>
	                            </span>

	                            <span v-else-if="compactorMonitor.monitorConfiguration?.last_picked_up">
	                                {{ $filters.format_date(compactorMonitor.monitorConfiguration.last_picked_up) }}
	                            </span>

	                            <span v-else>
	                                -
	                            </span>
	                        </td>
	                        <td v-show="form.selected_headers.includes('Next Pickup')" class="!border-gray-200">
		                        <span v-if="compactorMonitor?.monitorConfiguration?.latestOpenWorkOrder">
	                                <inertia-link :href="$route('work-orders.edit', compactorMonitor.monitorConfiguration.latestOpenWorkOrder.id)" class="link">
	                                    <span v-if="compactorMonitor.monitorConfiguration.latestOpenWorkOrder.scheduled_with_vendor_date" content="Pickup is scheduled" v-tippy>
	                                        {{ $filters.format_date(compactorMonitor.monitorConfiguration.latestOpenWorkOrder.scheduled_with_vendor_date) }}
	                                    </span>

	                                    <span v-else content="Pickup is requested" v-tippy>
	                                        {{ $filters.format_date(compactorMonitor.monitorConfiguration.latestOpenWorkOrder.requested_completion_date) }}
	                                    </span>
	                                </inertia-link>
	                            </span>
	                            <span v-else-if="compactorMonitor.monitorConfiguration?.projected_pickup_date" content="Pickup is forecasted" v-tippy>
	                                {{ $filters.format_date(compactorMonitor.monitorConfiguration?.projected_pickup_date) }}
	                            </span>

	                            <span v-else>
	                                -
	                            </span>
	                        </td>
	                        <td v-show="form.selected_headers.includes('Predictive Pickup Scheduling')" class="text-center w-auto !border-gray-200">
	                            <span v-if="!compactorMonitor.deviceService || !compactorMonitor.monitorConfiguration">
	                                -
	                            </span>
	                            <span v-else :content="compactorMonitor.monitorConfiguration.create_work_orders ? 'Enabled' : 'Disabled'" v-tippy>
	                                <icon name="power-off" class="inline fill-current h-4 w-4" :class="compactorMonitor.monitorConfiguration.create_work_orders ? 'text-green-600' : 'text-red-600'" />
	                            </span>
	                        </td>

		                    <td  v-show="form.selected_headers.includes('PM Scheduling')" class="text-center w-auto !border-gray-200">
	                            <span v-if="!compactorMonitor.deviceService || !compactorMonitor.monitorConfiguration">
	                                -
	                            </span>
			                    <span v-else :content="compactorMonitor.monitorConfiguration.create_preventative_maintenance_work_orders ? 'Enabled' : 'Disabled'" v-tippy>
	                                <icon name="power-off" class="inline fill-current h-4 w-4" :class="compactorMonitor.monitorConfiguration.create_preventative_maintenance_work_orders ? 'text-green-600' : 'text-red-600'" />
	                            </span>
		                    </td>
	                        <td class="text-center !border-gray-200">
		                        <row-action-dropdown class="z-5">
			                        <slot>
				                        <inertia-link :href="$route('compactor-monitors.edit', compactorMonitor.id)" class="block px-4 py-2 text-sm capitalize  text-gray-700 rounded hover:bg-d-blue-500 hover:text-white">
					                        Edit Assignment
				                        </inertia-link>
			                        </slot>
		                        </row-action-dropdown>
	                        </td>
	                    </clickable-table-row>
	                </tbody>
	            </table>
	        </div>

	        <pagination-2 v-if="compactorMonitors.data.length" :list="compactorMonitors"/>
	    </div>
		<div class="md:hidden">
			<div v-for="compactorMonitor in compactorMonitors.data" :id="compactorMonitor.id" :key="compactorMonitor.id">
				<index-card :href="$route('compactor-monitors.overview', compactorMonitor.id)">
					<template #header>
						<div class="relative grid grid-cols-5">
							<span v-if="compactorMonitor?.monitoredService" class="col-span-4 text-lg">
									{{ compactorMonitor.monitoredService?.location?.name ?? '' }}
							</span>
							<span class="text-right absolute right-0">
								<icon name="power-off"
								      class="h-5 w-5 mt-2 ml-2"
								      :class="[compactorMonitor.monitorConfiguration?.create_work_orders ? 'fill-green-700 fill-current' : 'fill-red-700 fill-current']"
								>
								</icon>
							</span>
						</div>

						<div class="flex items-center gap-x-2 text-sm">
							<div :class="[statusClasses[compactorMonitor?.connectivity_status], 'flex-none rounded-full']" :content="$filters.capitalize(compactorMonitor.connectivity_status)"  v-tippy>
								<div class="h-2 w-2 rounded-full bg-current" />
							</div>
							{{ compactorMonitor?.monitored_service_id }}
						</div>

					</template>
					<template #body>
						<div class="relative">
							<span class="text-right absolute right-0 top-5 text-md font-semibold">
								<span>Next Pickup: </span>
								<span v-if="compactorMonitor?.monitorConfiguration?.latestOpenWorkOrder">
			                        <inertia-link :href="$route('work-orders.edit', compactorMonitor.monitorConfiguration.latestOpenWorkOrder.id)" class="link">
			                            <span v-if="compactorMonitor.monitorConfiguration.latestOpenWorkOrder.scheduled_with_vendor_date" content="Pickup is scheduled" v-tippy>
			                                {{ compactorMonitor.monitorConfiguration.latestOpenWorkOrder.scheduled_with_vendor_date }}
			                            </span>

			                            <span v-else content="Pickup is requested" v-tippy>
			                                {{ compactorMonitor.monitorConfiguration.latestOpenWorkOrder.requested_completion_date }}
			                            </span>
			                        </inertia-link>
			                    </span>
								<span v-else-if="compactorMonitor.monitorConfiguration?.projected_pickup_date" content="Pickup is forecasted" v-tippy>
                                    {{ $filters.format_date(compactorMonitor.monitorConfiguration?.projected_pickup_date) }}
			                    </span>

								<span v-else>
	                                N/A
			                    </span>
							</span>
						</div>
					</template>
					<template #footer>
						<span v-if="compactorMonitor.monitorConfiguration" class="flex mt-10 items-center">
	                        <span class="mr-4 text-lg">{{ compactorMonitor.monitorConfiguration?.percentage_full ? compactorMonitor.monitorConfiguration?.percentage_full + '%' : 'N/A' }}</span>
	                        <div class="relative h-5 w-full bg-gray-200 rounded flex items-center">
			                    <div :style="`left:${compactorMonitor.monitorConfiguration?.target_percentage_full ?? 0}%`" class="w-0.5 h-full bg-d-orange-500 absolute items-center" />
			                    <div :style="`width:${compactorMonitor.monitorConfiguration.percentage_full}%`" class="h-full bg-d-cyan-400 rounded transition-all duration-5000 ease-in"/>
		                    </div>
	                    </span>
					</template>
				</index-card>
			</div>
			<pagination-2 v-if="compactorMonitors.data.length" :list="compactorMonitors"/>
		</div>

		<div v-if="!compactorMonitors.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
	        <icon name="sensor" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
	        <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Compactor Monitors Found</span>
        </div>
	</div>


    <slide-over ref="searchFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="compactor-monitors-filter-form" class="grid grid-cols-1 divide-y-2">
	            <div class="grid grid-cols-1 gap-x-4 gap-y-3 mt-4 items-center">
		            <div class="col-span-6 sm:col-span-1 inline">
			            <select-input id="stale monitors" name="needs_attention" class="col-span-6 sm:col-span-1" v-model="form.needs_attention" label="Needs Attention" icon="exclamation-triangle" icon-color="text-rose-400">
				            <option value="">All</option>
				            <option value="hide">Hide</option>
				            <option value="only">Only</option>
			            </select-input>
		            </div>
		            <div class="col-span-6 sm:col-span-1">
			            <select-input class="col-span-6 sm:col-span-1"  v-model="form.connectivity_status" label="Connectivity Status">
				            <option value="">Any</option>
				            <option v-for="status in statuses" :value="status">{{ $filters.capitalize(status) }}</option>
			            </select-input>
		            </div>
		            <div :key="clientCompaniesKey" class="col-span-6 sm:col-span-1">
			            <Combobox as="div" v-model="form.client_companies" multiple>
				            <ComboboxLabel class="form-label" for="client_companies">Client Companies</ComboboxLabel>

				            <div class="relative">
					            <ComboboxInput id="client_companies" name="client_companies" class="form-input" @focus="$event.target.select()" @blur="clientCompanyComboBoxQuery = ''" @change="clientCompanyComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

					            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
						            <!-- Heroicon name: solid/selector -->
						            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
						            </svg>
					            </ComboboxButton>

					            <ComboboxOptions v-if="clientCompanies.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						            <ComboboxOption v-for="clientCompany in filteredClientCompaniesOptions" :key="clientCompany.id" :value="clientCompany.id" as="template" v-slot="{ active, selected }">
							            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
	                                        <span :class="['block truncate', selected && 'font-semibold']">
	                                        {{ clientCompany.name }}
	                                        </span>

								            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                            <!-- Heroicon name: solid/check -->
	                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
	                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
	                                            </svg>
	                                        </span>
							            </li>
						            </ComboboxOption>
					            </ComboboxOptions>
				            </div>
			            </Combobox>
		            </div>
		            <div :key="vendorsKey" class="col-span-6 sm:col-span-1">
			            <Combobox as="div" v-model="form.vendors" multiple>
				            <ComboboxLabel class="form-label" for="client_companies">Vendors</ComboboxLabel>

				            <div class="relative">
					            <ComboboxInput id="vendors" name="vendors" class="form-input" @focus="$event.target.select()" @blur="vendorComboBoxQuery = ''" @change="vendorComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" />

					            <ComboboxButton class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
						            <!-- Heroicon name: solid/selector -->
						            <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
							            <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
						            </svg>
					            </ComboboxButton>

					            <ComboboxOptions v-if="vendors.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
						            <ComboboxOption v-for="vendor in filteredVendorsOptions" :key="vendor.id" :value="vendor.id" as="template" v-slot="{ active, selected }">
							            <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
	                                        <span :class="['block truncate', selected && 'font-semibold']">
	                                        {{ vendor.name }}
	                                        </span>

								            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                            <!-- Heroicon name: solid/check -->
	                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
	                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
	                                            </svg>
	                                        </span>
							            </li>
						            </ComboboxOption>
					            </ComboboxOptions>
				            </div>
			            </Combobox>
		            </div>
		            <div class="col-span-6 sm:col-span-1">
			            <select-input id="create-work-orders" name="create_work_orders" class="col-span-6 sm:col-span-1" v-model="form.create_work_orders" label="Predictive Pickup Scheduling">
				            <option value="">Any</option>
				            <option value="true">Enabled</option>
				            <option value="false">Disabled</option>
			            </select-input>
		            </div>
		            <div class="col-span-6 sm:col-span-1">
			            <select-input id="create-preventative-maintenance-work-orders" name="create_preventative_maintenance_work_orders" class="col-span-6 sm:col-span-1" v-model="form.create_preventative_maintenance_work_orders" label="PM Scheduling">
				            <option value="">Any</option>
				            <option value="true">Enabled</option>
				            <option value="false">Disabled</option>
			            </select-input>
		            </div>
		            <div class="col-span-6 sm:col-span-1 inline">
			            <select-input id="assignment" name="assignment" class="col-span-6 sm:col-span-1" v-model="form.assignment" label="Assignment">
				            <option value="">All</option>
				            <option value="unassigned">Unassigned</option>
				            <option value="assigned">Assigned</option>
			            </select-input>
		            </div>
	            </div>
	            <div class="grid grid-cols-1 gap-x-4 gap-y-4 mt-6 items-center pt-3">
		            <label class="">Last Pickup</label>
		            <div class="col-span-1">
			            <date-input v-model="form.last_pickup_begin_date" />

			            <span class="w-2/12 text-center">thru</span>

			            <date-input v-model="form.last_pickup_end_date" />
		            </div>
	            </div>
	            <loading-button class="btn btn-orange mr-auto my-5" :loading="saving" @click="applyFilters">
		            Apply Filters
	            </loading-button>
            </form>
        </template>
    </slide-over>
</template>

<script setup>
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import SortableTableHeader from '@/Shared/SortableTableHeader.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import {Head, router} from '@inertiajs/vue3';
    import {computed, inject, onMounted, reactive, ref, watch} from 'vue';
    import ColumnSelector from "@/Shared/ColumnSelector.vue";
    import SlideOver from "@/Shared/SlideOver.vue";
    import CompactorMonitorIndexHeader from "./Index/CompactorMonitorIndexHeader.vue";
    import RowActionDropdown from "../../Shared/RowActionDropdown.vue";
    import ClickableTableRow from "../../Shared/ClickableTableRow.vue";
    import SubNav from "./Index/SubNav.vue";
    import Pagination2 from "../../Shared/Pagination2.vue";
    import IndexCard from "../../Shared/IndexCard.vue";
    import axios from 'axios';


    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue'
    import CopyToClipboardButton from "../../Shared/CopyToClipboardButton.vue";
    import TooltipIcon from "../../Shared/TooltipIcon.vue";

	const props = defineProps({
	    errors: {
	        type: Object,
	        default: () => ({})
	    },

	    compactorMonitors: {
	        type: Object,
	        required: true
	    },

	    filters: {
	        type: Object,
	        required: true
	    },

	    statuses: {
	        type: Array,
	        required: true
	    },

	    needsAttention: {
	        type: Array,
	        required: true
	    },
	});

    const route = inject('route');

    const form = reactive({
	    search: props.filters.search,
	    sort_by: props.filters.sort_by,
	    sort_direction: props.filters.sort_direction,
	    per_page: props.filters.per_page,
	    create_work_orders: props.filters.create_work_orders ? props.filters.create_work_orders : '',
	    create_preventative_maintenance_work_orders: props.filters.create_preventative_maintenance_work_orders ? props.filters.create_preventative_maintenance_work_orders : '',
	    connectivity_status: props.filters.connectivity_status ? props.filters.connectivity_status : '',
	    last_pickup_begin_date: props.filters.last_pickup_begin_date,
	    last_pickup_end_date: props.filters.last_pickup_end_date,
	    needs_attention: props.filters.needs_attention ? props.filters.needs_attention : '',
	    assignment: props.filters.assignment ? props.filters.assignment : '',
	    client_companies: props.filters.client_companies ? props.filters.client_companies : [],
	    vendors: props.filters.vendors ? props.filters.vendors : [],
	    selected_headers: props.filters.selected_headers,
	});
    const headers = ref([]);
    const excludedHeaders = ref(['Needs Attention', 'Monitor Id', 'Service ID', '']);
    const saving = ref(false);
	const extracting = ref(false);
    const mounted = ref(false);
	const clientCompanies = ref([]);
	const clientCompaniesKey = ref(0);
	const vendors = ref([]);
	const vendorsKey = ref(0);
    const clientCompanyComboBoxQuery = ref('');
    const vendorComboBoxQuery= ref('');
    const statusClasses = ref({
	    'online': 'animate-pulse text-green-400 bg-green-400/10',
		    'offline': 'animate-pulse text-rose-400 bg-rose-400/10',
		    'inactive': 'text-gray-400 bg-gray-400/10',
	});
    const filtersInUse = ref(0);

    const table = ref(null);

    const throttledApplyFilters = throttle(applyFilters, 1000);

    /**
	     * Methods
	     */
    onMounted(() => {
        mounted.value = true;

        getTableHeaders();

        if (form.selected_headers.length === 0) {
            form.selected_headers = headers.value;
        }

	    filtersInUse.value = getFiltersUsed.value;
		fetchClientCompanies();
		fetchVendors();
    });

	function fetchClientCompanies() {
		axios.get(route('json.client-companies.index'), {
			params: {
				per_page: 1000,
				sort_by: 'name',
				sort_direction: 'asc',
				monitored: true,
			}
		}).then(response => {
			clientCompanies.value = response.data.data.sort(function (a, b) {
				let a_hash = (form.client_companies.includes(a.id) ? 0 : 1) + "_" + a.name + "_" + a.id;
				let b_hash = (form.client_companies.includes(b.id) ? 0 : 1) + "_" + b.name + "_" + b.id;

				return a_hash.localeCompare(b_hash);
			});
			clientCompaniesKey.value++;
		});
	}

	function fetchVendors() {
		axios.get(route('json.vendors.index'), {
			params: {
				per_page: 1000,
				sort_by: 'name',
				sort_direction: 'asc',
				monitored: true
			}
		}).then(response => {
			vendors.value = response.data.data.sort(function (a, b) {
				let a_hash = (form.vendors.includes(a.id) ? 0 : 1) + "_" + a.name + "_" + a.id;
				let b_hash = (form.vendors.includes(b.id) ? 0 : 1) + "_" + b.name + "_" + b.id;

				return a_hash.localeCompare(b_hash);
			});
			vendorsKey.value++;
		});
	}

    function exportData() {
		if (!extracting.value) {
			extracting.value = true;

			router.post(route('csv.compactor-monitors.index'), queryFilters.value, {
				onSuccess: () => { extracting.value = false; }
			});
		}
    }
    function applyFilters() {
	    if (mounted) {
		    router.get(route('compactor-monitors.index'), queryFilters.value);
		    filtersInUse.value = getFiltersUsed.value;
			saving.value = true;
	    }
    }

    function getTableHeaders() {
	    const thElements = table.value.querySelectorAll('th');
	    headers.value = Array.from(thElements)
		    .filter(th => !th.querySelector('img'))
		    .map(th => th.textContent)
		    .sort((a, b) => a.localeCompare(b));
    }

    function comboDisplayValue(option) {
        if (Array.isArray(option)) {
	        if (option.length > 1) {
		        return `${option.length} options selected`;
	        } else if (option.length === 1) {
		        return '1 option selected';
	        } else {
		        return 'No options selected';
	        }
        }

        return option ?? 'No filters selected';
    }

    watch(() => form.search, (newVal, oldVal) => {
	    throttledApplyFilters();
    });

	watch(() => form.per_page, (newVal, oldVal) => {
		applyFilters();
	});

    const queryFilters = computed(() => {
        let query = { ...form};

        return has_search_filters(query) ? query : {remember: 'forget'};
    });

    const filteredClientCompaniesOptions = computed(() => {
        return clientCompanyComboBoxQuery.value === ''
	        ? clientCompanies.value
	        : clientCompanies.value.filter((clientCompany) => {
		        return clientCompany.name.toLowerCase().includes(clientCompanyComboBoxQuery.value.toLowerCase());
	        });
    });

    const filteredVendorsOptions = computed(() => {
	        return vendorComboBoxQuery.value === ''
	        ? vendors.value
	        : vendors.value.filter((vendor) => {
		        return vendor.name.toLowerCase().includes(vendorComboBoxQuery.value.toLowerCase());
	        });
    });

    const subNavTabs = computed(() => {
        return [
            {name: 'Devices', href: route('compactor-monitors.index'), key: 'devices'},
            {name: 'Reports', href: '', key: 'reports', subnav: [
                {name: 'Cumulative Monitor Impact', href: route('compactor-monitors.reports.cumulative-portfolio-impact-summaries'), key: 'cumulative-monitor-report'},
                {name: 'Cumulative Pickup Details', href: route('compactor-monitors.reports.cumulative-portfolio-pickup-details'), key: 'cumulative-pickup-details'},
            ]},
        ]
    });

    const getFiltersUsed = computed(() => {
        const formFilters = [
            form.search,
            form.create_work_orders,
	        form.create_preventative_maintenance_work_orders,
            form.last_pickup_begin_date,
            form.last_pickup_end_date,
            form.connectivity_status,
            form.needs_attention,
            form.assignment,
            form.client_companies,
            form.vendors
        ];

        return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
    });
</script>