<template>
	<Menu as="div" class="relative inline-block text-left relative">
			<MenuButton @click="toggleMenu" class="flex items-center text-gray-400 z-0 px-6">
				<span class="sr-only">Open options</span>
				<icon name="ellipsis" class="h-6 w-6 text-gray-400 fill-current" aria-hidden="true"/>
			</MenuButton>
		<transition enter-active-class="transition ease-out duration-100" enter-from-class="transform opacity-0 scale-95" enter-to-class="transform opacity-100 scale-100" leave-active-class="transition ease-in duration-75" leave-from-class="transform opacity-100 scale-100" leave-to-class="transform opacity-0 scale-95">
			<MenuItems :class="{ 'bottom-2': showAbove }" class="absolute right-0 z-20 mt-2 w-auto origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none z-40">
				<div class="">
					<slot></slot> <!-- Here is your slot -->
				</div>
			</MenuItems>
		</transition>
	</Menu>
</template>

<script setup>
import { ref } from 'vue';
import { Menu, MenuButton, MenuItems } from '@headlessui/vue'
import Icon from "./Icon.vue";

// data
let showAbove = ref(false);

// methods
const toggleMenu = (event) => {
	const spaceFromBottom = window.innerHeight - event.target.getBoundingClientRect().bottom;
	if (spaceFromBottom < 260) {
		showAbove.value = true;
	} else {
		showAbove.value = false;
	}
};
</script>