<template>
    <Head :title="`Compactor Monitor - ${compactorMonitor.id}`"/>

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">Home</inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('compactor-monitors.index')" class="breadcrumb-link">Compactor Monitors
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <span>{{ compactorMonitor.monitoredService?.location.name ?? compactorMonitor.id }}</span>
        </nav>
    </Teleport>

    <!-- Page header -->
	<div class="lg:sticky lg:top-0 lg:bg-white lg:z-20">
		<CompactorMonitorHeader :compactor-monitor="compactorMonitor" />
		<SubNav :compactor-monitor="compactorMonitor" current-tab="fullness"/>
	</div>

    <div class="max-w-7xl mx-auto 3xl:max-w-screen-2xl lg:grid-flow-col-dense 4xl:max-w-none">
		<div class="grid my-3 gap-6 lg:grid-cols-6 mx-2 md:mx-4">
		    <section class="lg:col-span-2 bg-white sm:rounded-lg lg:order-2">
			    <section aria-labelledby="configuration-title">
				    <div class=" bg-white shadow sm:rounded-lg">
					    <div class="px-4 py-5 sm:px-6">
						    <dl v-if="compactorMonitor.monitorConfiguration"
						        class="grid grid-cols-1 gap-x-4 gap-y-8 md:grid-cols-2">
							    <div class="col-span-2 text-xl flex">Predictive Pickup Scheduling
								    <icon name="power-off"
								          :content="compactorMonitor.monitorConfiguration.create_work_orders ? 'Predictive Pickup Scheduling is on!' : 'Predictive Pickup Scheduling is off!'"
								          class="h-4 w-4 mt-2 ml-2"
								          :class="[compactorMonitor.monitorConfiguration.create_work_orders ? 'fill-green-700 fill-current' : 'fill-red-700 fill-current']"
								          v-tippy/>
							    </div>
							    <div class="col-span-2 md:col-span-1">
								    <dt class="text-sm font-medium text-gray-500">Pickup Scheduling Start Date</dt>
								    <dd class="mt-1 text-sm text-gray-900">
									    <template
										    v-if="compactorMonitor.monitorConfiguration.pickup_management_start_date">
										    {{
											    $filters.format_date(compactorMonitor.monitorConfiguration.pickup_management_start_date)
										    }}
									    </template>

									    <template v-else>
										    N/A
									    </template>
								    </dd>
							    </div>
							    <div class="col-span-2 md:col-span-1">
								    <dt class="text-sm font-medium text-gray-500">Pickup Request Lead Time (business days)</dt>
								    <dd class="mt-1 text-sm text-gray-900">{{
										    compactorMonitor.monitorConfiguration.create_work_order_x_days_prior_to_projection
									    }}
								    </dd>
							    </div>
							    <div class="col-span-2 md:col-span-1">
								    <dt class="text-sm font-medium text-gray-500">Maximum Tonnage Capacity</dt>
								    <dd class="mt-1 text-sm text-gray-900">
									    {{ compactorMonitor.monitorConfiguration.tonnage_capacity }}
								    </dd>
							    </div>
							    <div class="col-span-2 md:col-span-1">
								    <dt class="text-sm font-medium text-gray-500">Target Percentage Full at Pickup</dt>
								    <dd class="mt-1 text-sm text-gray-900">
									    {{ compactorMonitor.monitorConfiguration.target_percentage_full }}% (~{{ (Math.round(((compactorMonitor.monitorConfiguration.target_percentage_full / 100 * compactorMonitor.monitorConfiguration.tonnage_capacity) + Number.EPSILON) * 100) / 100).toFixed(2) }} tons)
								    </dd>
							    </div>
							    <div class="col-span-2 border-t"></div>
							    <div class="col-span-2">
								    <dt class="text-sm font-medium text-gray-500">Never request pickups MORE frequently than </dt>
								    <dd class="mt-1 text-sm text-gray-900 flex">
									    {{ compactorMonitor.monitorConfiguration.min_days_between_pickups }}
									    <p class="text-gray-500 ml-1"> calendar days</p>
								    </dd>
							    </div>
							    <div class="col-span-2">
								    <dt class="text-sm font-medium text-gray-500">Never request pickups LESS frequently than</dt>
								    <dd class="mt-1 text-sm text-gray-900 flex">
									    {{ compactorMonitor.monitorConfiguration.max_days_between_pickups }}
									    <p class="text-gray-500 ml-1"> calendar days</p>

								    </dd>
							    </div>
						    </dl>
						    <div v-else class="text-center">
							    <icon name="cogs" class="mx-auto h-12 w-12 text-gray-400 fill-current"/>
							    <h3 class="mt-2 text-sm font-medium text-gray-900">No configuration</h3>
						    </div>
					    </div>
				    </div>
			    </section>
		    </section>

	            <!-- Fullness Chart !-->
            <section class="lg:col-span-4 lg:order-1" aria-labelledby="fullness-title">
                <div class="bg-white shadow sm:rounded-lg">
                    <div class="px-4 py-5 sm:px-6">
	                    <h2 id="fullness-title" class="text-xl leading-6 font-medium text-gray-900">
		                    Fullness</h2>
                    </div>

                    <div class="sm:flex sm:flex-row grid grid-cols-2 mx-auto px-4 py-2 sm:px-6">
                        <date-input v-model="form.begin_date" label="Start" class="mr-3"/>
                        <date-input v-model="form.end_date" label="End"/>
                        <div class="hidden lg:block lg:items-end lg:self-end">
                            <inertia-link :href="$route('work-orders.index', {search: compactorMonitor.monitored_service_id})" class="btn btn-gray my-auto ml-4 ">
	                            View All Work Orders
                            </inertia-link>
                        </div>
                    </div>
                    <div class="block lg:hidden lg:items-end lg:self-end py-4 lg:-py-4">
                        <inertia-link :href="$route('work-orders.index', {search: compactorMonitor.monitored_service_id})" class="btn btn-gray my-auto ml-4 ">
	                        View All Work Orders
                        </inertia-link>
                        <!--		                            TODO: add create missing work order button that leads to work order create page prepopulated; source:internal, requested_action:empty and return-->
                    </div>

                    <div class="border-t border-gray-200 px-4 py-1 sm:px-6">
                        <div v-if="workOrders?.length">
                            <compactor-monitor-fullness-column-chart :forecasted-fullness="forecastedFullness"
                                                                     :logged-fullness="loggedFullness"
                                                                     :predicted-pickup-flag="predictedPickupFlag"
                                                                     :pickups="pickupsFormatted"
                                                                     :target-percentage-full="compactorMonitor.monitorConfiguration.target_percentage_full"
                                                                     :loading="fetchingFullness"/>
                        </div>
                        <div v-else class="px-4 py-4 grid items-center text-2xl font-semibold"
                             style="height: 400px; background-image: url('/images/compactor_graph_blurred_bw.png')">
                            <div class="text-center">
                                <template v-if="!loggedFullness">
                                    Fetching data. Please wait...
                                </template>

                                <template v-else>
                                    No fullness data available.
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
	    </div>
    </div>
</template>

<script>
    import Icon from '@/Shared/Icon.vue';
    import DateInput from '@/Shared/DateInput.vue';
    import LoadingButton from "../../../Shared/LoadingButton.vue";
    import CompactorMonitorFullnessColumnChart from '@/Shared/Reports/CompactorMonitorFullnessColumnChart.vue';
    import CompactorMonitorRunsHeatmap from '@/Shared/Reports/CompactorMonitorRunsHeatmap.vue';
    import ParticleVitalsAreaChart from "../../../Shared/Reports/ParticleVitalsAreaChart.vue";
    import { differenceInCalendarDays, format, parseISO, fromUnixTime, differenceInDays } from 'date-fns';
    import { debounce } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import axios from "axios";
    import CompactorMonitorHeader from "./CompactorMonitorHeader.vue";
    import SubNav from "./SubNav.vue";
    import CompactorMonitorWorkOrderTonnageColumnChart from "../../../Shared/Reports/CompactorMonitorWorkOrderTonnageColumnChart.vue";

export default {
    components: {
	    CompactorMonitorWorkOrderTonnageColumnChart,
	    SubNav,
	    CompactorMonitorHeader,
        ParticleVitalsAreaChart,
        LoadingButton,
        Icon,
        DateInput,
        CompactorMonitorFullnessColumnChart,
        CompactorMonitorRunsHeatmap,
        Head,
    },

    props: {
        errors: {
            type: Object,
            default: () => ({})
        },

        compactorMonitor: {
            type: Object,
            required: true
        },

        lastPickupWorkOrder: {
            type: Object,
            required: true
        },

        pickupWorkOrders: {
            type: Object,
            required: true
        },

        lastRan: {
            type: String,
            required: true
        },

        monitorSummary: {
            type: Object,
            required: true
        },

        runsSeries: {
            type: Array,
            required: true
        },

	    daysSinceLastPickup: {
            required: false
	    },

	    preMonitorValues: {
		    type: Object,
		    required: false
	    },
    },

    data() {
        return {
            form: {
                begin_date: null,
                end_date: format(Date.now(), 'yyyy-MM-dd'),
            },
            mounted: false,
            refreshingVitals: false,
	        fetchingFullness: false,
            pickups: null,
            loggedFullness: null,
            forecastedFullness: null,
	        workOrders: null,
	        statuses: {
		        'online': 'animate-pulse text-green-400 bg-green-400/20',
		        'offline': 'animate-pulse text-rose-400 bg-rose-400/20',
		        'inactive': 'text-gray-400 bg-gray-400/20',
	        },
        }
    },

    mounted() {
        this.form.begin_date = this.threeMonthsAgo();
        this.getCompactorMonitorFullness();

        this.mounted = true;
    },

    unmounted() {
        Echo.leave(`compactor_monitors.${this.compactorMonitor.id}`);
    },

    watch: {
        'form.begin_date': debounce(function() {
            if (!this.mounted) {
                return;
            }

            this.getCompactorMonitorFullness();
        }, 500),

        'form.end_date': debounce(function() {
            if (!this.mounted) {
                return;
            }

            this.getCompactorMonitorFullness();
        }, 500),
    },

    methods: {
        threeMonthsAgo() {
            const now = new Date();
            // Adjust the following to use the start of the month:
            const threeMonthsAgo = new Date(now.getFullYear(), now.getMonth() - 3, now.getDate()).setDate(1);

            return format(threeMonthsAgo, 'yyyy-MM-dd');
        },

        getCompactorMonitorFullness() {
            this.fetchingFullness = true;

            axios.get(this.$route('compactor-monitors.fullness-data', this.compactorMonitor.id),{
                params: {
                    begin_date: this.form.begin_date,
                    end_date: this.form.end_date,
                }
            })
            .then(response => {
                this.pickups = response.data.pickups;
                this.loggedFullness = response.data.loggedFullness;
                this.forecastedFullness = response.data.forecastedFullness;
				this.workOrders = response.data.workOrders;
                this.fetchingFullness = false;
            })
                .catch(error => {
                this.$toast.error('Failed to fetch compactor monitor fullness.');
            })
        },
    },

    computed: {
        upcomingPickupDateFormatted() {
            if (!this.monitorSummary.upcoming_pickup_date) {
                return '';
            }

            return `${format(parseISO(this.monitorSummary.upcoming_pickup_date), 'iii, MMM do')} (${differenceInCalendarDays(parseISO(this.monitorSummary.upcoming_pickup_date), new Date())} days)`;
        },

        pickupsFormatted() {
            if (!this.pickups.length) {
                return [];
            }
			let vueContext = this;
            return this.pickups.map((pickup) => {
	            let tonnage = pickup.tonnage ? pickup.tonnage : '-';
	            const pickupPoint = {
		            x: pickup.pickup_timestamp,
		            y: 80,
		            title: tonnage,
		            text: `<div class="mt-auto">
								Tonnage: <strong>${tonnage}</strong>
					            <br>Days between pickups: <strong>${pickup.days_between_pickups}</strong>
					            <br><p>Click to view the work order</p>
							</div>`,
		            color: 'green'
	            };
	            pickupPoint.events = {
		            click: function () {
			            const url = vueContext.$route('work-orders.show', pickup.work_order_id);
			            vueContext.$inertia.get(url);
		            }
	            };
	            return pickupPoint;
            });
        },

        predictedPickupFlag() {
            if (!this.loggedFullness.length) {
                return [];
            }

            if (this.forecastedFullness.length && this.loggedFullness.slice(-1)[0].y < this.compactorMonitor.monitorConfiguration.target_percentage_full) {
                let daysUntilPickup = differenceInDays(fromUnixTime(this.forecastedFullness.slice(-1)[0].x / 1000), new Date().getTime())
	            let forecastDate = new Date(this.forecastedFullness.slice(-1)[0].x).toLocaleDateString();
                return [{
                    x: this.forecastedFullness.slice(-1)[0].x,
                    y: this.forecastedFullness.slice(-1)[0].y,
                    title: this.monitorSummary.upcoming_pickup_date || this.monitorSummary.projected_pickup_date ? `Forecasted Next Pickup: ${this.upcomingPickupDateFormatted}` : forecastDate,
                    text: `Capacity target: <strong>${this.compactorMonitor.monitorConfiguration.target_percentage_full}%</strong>
                        <br> Projected to need picked up in around <strong>${daysUntilPickup} day(s)</strong>`
                }];
            } else {
                return [{
                    x: this.loggedFullness.slice(-1)[0].x,
                    y: this.loggedFullness.slice(-1)[0].y,
                    title: 'Over Target',
                    text: `Capacity target: <strong>${this.compactorMonitor.monitorConfiguration.target_percentage_full}%</strong>
                        <br> Currently at: <strong>${this.loggedFullness.slice(-1)[0].y}%</strong>`
                }];
            }
        }
    },
}
</script>