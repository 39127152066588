<template>
    <Head title="Pickup Details" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">
                Home
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('compactor-monitors.index')" class="breadcrumb-link">
                Compactor Monitors
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('compactor-monitors.overview', compactorMonitor.id)" class="breadcrumb-link">
                {{ compactorMonitor.monitoredService?.location.name ?? compactorMonitor.id }}
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>
                Reports
            </span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>
                Pickup Details
            </span>
        </nav>
    </Teleport>

	<div class="lg:sticky lg:top-0 bg-white z-20">
		<CompactorMonitorHeader :compactor-monitor="compactorMonitor" />
		<SubNav :compactor-monitor="compactorMonitor" current-tab="pickup-details"/>
	</div>

	<div class="max-w-7xl mx-auto 3xl:max-w-screen-2xl my-4 4xl:max-w-none">
		<div class="mx-2 md:mx-3 lg:mx-4">
			<div class="grid grid-cols-2 gap-x-4 gap-y-6 my-3 items-center ">
				<div class="text-3xl mt-auto flex">
					Pickup Details
					<tooltip-icon class="mt-1 ml-2" tooltip="Details of each pickup that has occurred"/>
				</div>

				<div class="flex col-start-1 col-span-2 sm:col-span-1 sm:col-start-2 sm:justify-self-end">
					<button type="button" class=" mt-6 mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white font-semibold items-center border border-gray-400 text-sm  rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.reportFilterSlideOver.show()">
	                    <span class="inline-flex px-3 py-2">
	                        <icon name="filter" class="w-5 h-5 text-gray-400 fill-current" />
	                        <span class="ml-2">Filters</span>
	                    </span>

						<span class="relative px-3 py-2 inline-flex items-center bg-d-accent-100 text-sm font-semibold text-d-accent-900 rounded-r-md">
	                        {{ filtersInUse }}
	                    </span>
					</button>
					<inertia-link :href="$route('compactor-monitors.reports.pickup-details', compactorMonitor.id, {remember: 'forget'})" class="btn btn-gray mt-6 mr-2">Clear</inertia-link>


					<loading-button :loading="state === 'exporting'" class="mt-6 btn btn-gray" @click="exportData">
						Export to CSV
					</loading-button>
				</div>
			</div>

			<div>
				<div class="relative w-full overflow-x-auto shadow rounded p-5 ">
					<table class="relative table table-condensed  " ref="table">
						<thead class="font-semibold">
						<tr>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 truncate !border-gray-500">Work Order</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Service</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Work Order Source</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Requested Completion</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Scheduled Completion</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Actual Completion</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Haul Cost</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Rate Calculation</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Tons</th>
							<th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500 ">Weight Measurement</th>
						</tr>
						</thead>

						<tbody>
						<tr v-for="record in reportData.data" class="-border-b overflow-y-auto">
							<td class="!border-gray-200">
								<inertia-link v-if="record.work_order_id" class="link" :href="$route('work-orders.show', record.work_order_id)">
									{{ record.work_order_number }}
								</inertia-link>
							</td>
							<td class="!border-gray-200">
								<inertia-link class="link" :href="$route('services.show', record.service_id)">
									{{ record.service_id }}
								</inertia-link>
							</td>
							<td class="!border-gray-200">{{ record.work_order_source }}</td>
	                        <td class="!border-gray-200">{{ record.work_order_requested_completion_date ? $filters.format_date(record.work_order_requested_completion_date) : '' }}</td>
	                        <td class="!border-gray-200">{{ record.work_order_scheduled_with_vendor_for ? $filters.format_date(record.work_order_scheduled_with_vendor_for) : '' }}</td>
	                        <td class="!border-gray-200">
	                            <inertia-link class="link" :href="$route('pickups.show', record.pickup_id)">
	                                {{ $filters.format_date(record.pickup_date) }}
	                            </inertia-link>
	                        </td>
							<td class="!border-gray-200">{{ record.haul_cost ? $filters.format_money(record.haul_cost) : '' }}</td>
							<td class="!border-gray-200">{{ record.rate_calculation }}</td>
							<td class="!border-gray-200">{{ $filters.roundWithPrecision(record.tons, 2) }}</td>
							<td class="!border-gray-200">{{ record.weight_measurement }}</td>
						</tr>
						</tbody>
					</table>
				</div>

				<pagination-2 :list="reportData" />
			</div>

			<div v-if="!reportData.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
				<icon name="dumpster" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
				<span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Report Data</span>
			</div>
		</div>
	</div>

    <slide-over ref="reportFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="pickup-details-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
	            <div class="col-span-6 sm:col-span-1">
		            <date-input id="start_date" name="start_date" class="col-span-6 sm:col-span-1 mb-5" v-model="form.start_date" label="Start Date"/>
		            <date-input id="end_date" name="end_date" class="col-span-6 sm:col-span-1 mb-5" v-model="form.end_date" label="End Date"/>
					<select-input id="cost_filter" name="cost_filter" class="col-span-6 sm:col-span-1 mb-5" v-model="form.cost_filter" label="Cost Filter">
						<option value="All">All</option>
						<option value="Actual">Actual</option>
						<option value="Estimated">Estimated</option>
					</select-input>
	            </div>
            </form>
        </template>
    </slide-over>

</template>

<script>
    import DateInput from '@/Shared/DateInput.vue';
    import DropdownLink from '@/Shared/DropdownLink.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import ReportingTable from '@/Shared/ReportingTable.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import { has_search_filters } from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import { Head } from '@inertiajs/vue3';
    import SlideOver from "@/Shared/SlideOver.vue";

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';
    import SubNav from "../Show/SubNav.vue";
    import CompactorMonitorHeader from "../Show/CompactorMonitorHeader.vue";
    import TooltipIcon from "../../../Shared/TooltipIcon.vue";
    import Pagination2 from "../../../Shared/Pagination2.vue";

    export default {
        components: {
	        Pagination2,
	        TooltipIcon,
	        CompactorMonitorHeader, SubNav,
            DateInput,
            DropdownLink,
            Icon,
            LoadingButton,
            ReportingTable,
            SelectInput,
            Head,
            SlideOver,

            // Tailwind UI combobox.
            Combobox,
            ComboboxButton,
            ComboboxInput,
            ComboboxLabel,
            ComboboxOption,
            ComboboxOptions,
        },

        props: {
            reportData: {
                type: Object,
                required: true
            },

            compactorMonitor: {
                type: Object,
                required: true
            },

            filters: Object
        },

        data() {
            return {
                form: {
                    start_date: this.filters.start_date,
	                end_date: this.filters.end_date,
	                cost_filter: this.filters.cost_filter,
                },
                saving: false,
                state: 'passive',
                mounted: false,
                filtersInUse: 0,
                clientCompanyComboBoxQuery: '',
                locationsComboBoxQuery: '',
            }
        },

        mounted() {
            this.mounted = true;

            this.filtersInUse = this.getFiltersUsed;

            this.applyFormWatcher();
        },

        methods: {
            exportData() {
                this.saving = 'exporting';

                this.$inertia.post(this.$route('csv.compactor-monitors.reports.pickup-details', this.compactorMonitor.id), {...this.form}, {
                    onFinish: () => { this.saving = 'passive'; }
                });
            },

            comboDisplayValue(option) {
                if (Array.isArray(option)) {
                    if (option.length > 1) {
                        return `${option.length} filters selected`;
                    } else if (option.length === 1) {
                        return '1 filter selected';
                    } else {
                        return 'No filters selected';
                    }
                }

                return option ?? 'No filters selected';
            },

            applyFormWatcher() {
                this.$watch(
                    'form',
                    throttle(function() {
                        if(this.mounted) {
                            this.$inertia.get(this.$route("compactor-monitors.reports.pickup-details", this.compactorMonitor.id), this.queryFilters, {preserveState: true});
                            this.filtersInUse = this.getFiltersUsed;
                        }
                    }, 150),
                    {deep: true}
                );
            },
        },

        computed: {
            queryFilters() {
                let query = { ...this.form};

                return has_search_filters(query) ? query : {remember: 'forget'};
            },

            getFiltersUsed() {
                const form = this.form;
                const formFilters = [
					form.start_date,
					form.end_date,
					form.cost_filter !== 'all',
                ];

                return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
            }
        },
    }
</script>