<template>
    <Head title="Cumulative Portfolio Pickup Details" />

    <Teleport to="[data-slot='breadcrumbs']" v-if="mounted">
        <nav class="breadcrumbs">
            <inertia-link :href="$route('app.dashboard')" class="breadcrumb-link">
                Home
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6"/>

            <inertia-link :href="$route('compactor-monitors.index')" class="breadcrumb-link">
                Compactor Monitors
            </inertia-link>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>
                Reports
            </span>

            <icon name="angle-right" class="inline text-gray-600 fill-current h-6 w-6" />

            <span>
                Cumulative Portfolio Pickup Details
            </span>
        </nav>
    </Teleport>

    <div class="max-w-7xl mx-auto 3xl:max-w-screen-2xl my-0 4xl:max-w-none">
        <div class="divide-y md:divide-none">
            <CompactorMonitorIndexHeader />
            <SubNav class="pt-8 md:pt-0 md:mt-8" current-tab="cumulative-pickup-details" :tabs="subNavTabs()" />
        </div>

        <div class="mx-2 md:mx-3 lg:mx-4">
            <div class="grid grid-cols-1 lg:grid-cols-2 gap-x-4 gap-y-2 my-1 items-center">
	            <div class="text-3xl mt-auto flex">
		            Cumulative Pickup Details
		            <tooltip-icon class="mt-1 ml-2" tooltip="Details of each pickup that has occurred"/>
	            </div>
	            <div class="print:hidden lg:grid md:grid-cols-4 lg:grid-cols-5 inline-flex col-start-1 col-span-2 sm:col-span-1 md:col-start-2 justify-center mr-4 md:mr-0 justify-self-end mt-auto">
                    <button type="button" class="lg:col-span-2 mt-6 mr-2 inline-flex divide-x divide-gray-400 text-gray-800 bg-white md:font-semibold items-center border border-gray-400  rounded-md transition hover:bg-gray-100 focus:outline-none focus:shadow-outline-gray" @click="$refs.reportFilterSlideOver.show()">
	                    <span class="inline-flex px-3 py-2">
	                        <icon name="filter" class="w-6 h-6 md:w-5 md:h-5 text-gray-400 fill-current" />
	                        <span class="hidden md:block ml-2">Filters</span>
	                    </span>
                        <span class="relative px-3 py-2  w-full items-center bg-d-accent-100  md:font-semibold text-d-accent-900 rounded-r-md">
	                        {{ filtersInUse }}
	                    </span>
                    </button>

                    <inertia-link :href="$route('compactor-monitors.reports.cumulative-portfolio-pickup-details', {remember: 'forget'})" class="col-span-1 btn btn-gray mt-6 mr-2 text-md text-gray-800 justify-center">Clear</inertia-link>

                    <loading-button :loading="state === 'exporting'" class="hidden md:block col-span-2 mt-6 btn btn-gray font-semibold justify-center w-auto" @click="exportData">
                        Export to CSV
                    </loading-button>
                </div>
            </div>

            <div class="shadow rounded p-5">
                <div class="relative w-full overflow-x-auto">
                    <table class="table table-condensed overflow-x-scroll" ref="table">
                        <thead class="font-semibold">
                        <tr>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 truncate !border-gray-500">Location</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 truncate !border-gray-500">Work Order</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Service</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Requested Completion</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Scheduled Completion</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Actual Completion</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Haul Cost</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Rate Calculation</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500">Tons</th>
                            <th scope="col" class="px-3 py-3.5 text-left font-semibold text-gray-900 !border-gray-500 ">Weight Measurement</th>
                        </tr>
                        </thead>

                        <tbody>
                        <tr v-for="record in reportData.data">
                            <td class="!border-gray-200 wrap">
                                <inertia-link class="link" :href="$route('locations.show', record.location_id)">
                                    {{ record.location_name }}
                                </inertia-link>
                            </td>
                            <td class="!border-gray-200">
                                <inertia-link v-if="record.work_order_id" class="link" :href="$route('work-orders.show', record.work_order_id)">
                                    {{ record.work_order_number }}
                                </inertia-link>
                            </td>
                            <td class="!border-gray-200">
                                <inertia-link class="link" :href="$route('services.show', record.service_id)">
                                    {{ record.service_id }}
                                </inertia-link>
                            </td>
                            <td class="!border-gray-200">{{ record.work_order_requested_completion_date ? $filters.format_date(record.work_order_requested_completion_date) : '' }}</td>
                            <td class="!border-gray-200">{{ record.work_order_scheduled_with_vendor_for ? $filters.format_date(record.work_order_scheduled_with_vendor_for) : '' }}</td>
                            <td class="!border-gray-200">
                                <inertia-link class="link" :href="$route('pickups.show', record.pickup_id)">
                                    {{ $filters.format_date(record.pickup_date) }}
                                </inertia-link>
                            </td>
                            <td class="!border-gray-200">{{ record.haul_cost ? $filters.format_money(record.haul_cost) : '' }}</td>
                            <td class="!border-gray-200">{{ record.rate_calculation }}</td>
                            <td class="!border-gray-200">{{ $filters.roundWithPrecision(record.tons, 2) }}</td>
                            <td class="!border-gray-200">{{ record.weight_measurement }}</td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>

	        <pagination-2 v-if="reportData.data.length" :list="reportData" />

            <div v-if="!reportData.data.length" class="empty-state mt-16 md:mt-24 lg:mt-32">
                <icon name="dumpster" class="empty-state-icon h-16 w-16 md:h-24 md:w-24 lg:h-32 lg:w-32" />
                <span class="empty-state-message text-2xl md:text-3xl lg:text-4xl">No Report Data</span>
            </div>
        </div>
	</div>

    <slide-over ref="reportFilterSlideOver">
        <template #header="{close}">
            <div class="bg-d-navy-500 border-solid border-b-2 border-d-orange-500 px-4 py-6 sm:px-6">
                <div class="flex items-start justify-between">
                    <h2 class="text-lg font-medium text-white" id="slide-over-title">Filter By</h2>
                    <div class="ml-3 flex h-7 items-center">
                        <button @click="close" type="button" class="rounded-md text-gray-100 hover:text-white focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                            <span class="sr-only">Close panel</span>
                            <svg class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" aria-hidden="true">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                            </svg>
                        </button>
                    </div>

                </div>
            </div>
        </template>

        <template #body>
            <form @submit.prevent id="cumulative-portfolio-pickup-details-filter-form" class="grid grid-cols-1 gap-x-4 gap-y-6 my-2 mb-4">
	            <div class="col-span-6 sm:col-span-1">
                    <div>
                        <span class="font-bold pb-2">General</span>
                        <Combobox as="div" class="col-span-6 sm:col-span-1 mb-5" v-model="form.client_companies" multiple @update:model-value="filterSelectedLocations">
                            <ComboboxLabel class="form-label" for="locations">Client Companies</ComboboxLabel>

                            <div class="relative">

                                <ComboboxInput id="client-companies" name="client-companies" class="form-input" @focus="$event.target.select()" @change="clientCompaniesComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" autocomplete="off"/>

                                <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <!-- Heroicon name: solid/selector -->
                                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </ComboboxButton>

                                <ComboboxOptions v-if="sortedClientCompanyOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ComboboxOption v-for="clientCompany in sortedClientCompanyOptions" :key="clientCompany.id" :value="clientCompany.id" as="template" v-slot="{ active, selected }">
                                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ clientCompany.name }}
                                        </span>

                                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                        <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                        </li>
                                    </ComboboxOption>
                                </ComboboxOptions>
                            </div>
                        </Combobox>

                        <Combobox as="div" class="col-span-6 sm:col-span-1 mb-5" v-model="form.locations" multiple>
                            <ComboboxLabel class="form-label" for="locations">Locations</ComboboxLabel>

                            <div class="relative">

                                <ComboboxInput id="locations" name="locations" class="form-input" @focus="$event.target.select()" @change="locationsComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" autocomplete="off"/>

                                <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <!-- Heroicon name: solid/selector -->
                                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </ComboboxButton>

                                <ComboboxOptions v-if="sortedLocationOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ComboboxOption v-for="location in sortedLocationOptions" :key="location.id" :value="location.id" as="template" v-slot="{ active, selected }">
                                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ location.name }}
                                        </span>

                                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                        <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                        </li>
                                    </ComboboxOption>
                                </ComboboxOptions>
                            </div>
                        </Combobox>

                        <Combobox as="div" class="col-span-6 sm:col-span-1 mb-5" v-model="form.vendors" multiple>
                            <ComboboxLabel class="form-label" for="locations">Vendors</ComboboxLabel>

                            <div class="relative">

                                <ComboboxInput id="vendors" name="vendors" class="form-input" @focus="$event.target.select()" @change="vendorsComboBoxQuery = $event.target.value" :display-value="comboDisplayValue" autocomplete="off"/>

                                <ComboboxButton  class="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
                                    <!-- Heroicon name: solid/selector -->
                                    <svg class="h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                        <path fill-rule="evenodd" d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z" clip-rule="evenodd" />
                                    </svg>
                                </ComboboxButton>

                                <ComboboxOptions v-if="sortedVendorOptions.length > 0" class="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                    <ComboboxOption v-for="vendor in sortedVendorOptions" :key="vendor.id" :value="vendor.id" as="template" v-slot="{ active, selected }">
                                        <li :class="['relative cursor-default select-none py-2 pl-3 pr-9', active ? 'bg-d-orange-600 text-white' : 'text-gray-900']">
                                        <span :class="['block truncate', selected && 'font-semibold']">
                                            {{ vendor.name }}
                                        </span>

                                            <span v-if="selected" :class="['absolute inset-y-0 right-0 flex items-center pr-4', active ? 'text-white' : 'text-d-orange-600']">
	                                        <!-- Heroicon name: solid/check -->
                                            <svg class="h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                                                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                                            </svg>
                                        </span>
                                        </li>
                                    </ComboboxOption>
                                </ComboboxOptions>
                            </div>
                        </Combobox>

                        <select-input id="cost_filter" name="cost_filter" class="col-span-6 sm:col-span-1 mb-5" v-model="form.cost_filter" label="Cost Filter">
                            <option value="All">All</option>
                            <option value="Actual">Actual</option>
                            <option value="Estimated">Estimated</option>
                        </select-input>
                    </div>

                    <div>
                        <span class="font-bold pb-2">Actual Completion Date</span>
                        <date-input id="start_date" name="start_date" class="col-span-6 sm:col-span-1 mb-5" v-model="form.start_date" label="Start Date"/>
                        <date-input id="end_date" name="end_date" class="col-span-6 sm:col-span-1 mb-5" v-model="form.end_date" label="End Date"/>
                    </div>
	            </div>
            </form>
        </template>
    </slide-over>

</template>

<script setup>
    import DateInput from '@/Shared/DateInput.vue';
    import Icon from '@/Shared/Icon.vue';
    import LoadingButton from '@/Shared/LoadingButton.vue';
    import SelectInput from '@/Shared/SelectInput.vue';
    import {filters as $filters, has_search_filters} from '@/Shared/Utils/Filters.js';
    import { throttle } from 'lodash-es';
    import { Head, router } from '@inertiajs/vue3';
    import SlideOver from "@/Shared/SlideOver.vue";
    import {computed, inject, onMounted, reactive, ref, watch} from 'vue';

    // Tailwind UI combobox.
    import {
        Combobox,
        ComboboxButton,
        ComboboxInput,
        ComboboxLabel,
        ComboboxOption,
        ComboboxOptions,
    } from '@headlessui/vue';
    import SubNav from "../Index/SubNav.vue";
    import CompactorMonitorIndexHeader from "@/Pages/CompactorMonitors/Index/CompactorMonitorIndexHeader.vue";
    import TooltipIcon from "../../../Shared/TooltipIcon.vue";
    import Pagination2 from "../../../Shared/Pagination2.vue";

    /**
     * Props
     */
    const props = defineProps({
        clientCompanies: {
            type: Array,
            required: true
        },

        filters: Object,

        locations: {
            type: Array,
            required: true
        },

        reportData: {
            type: Object,
            required: true
        },

        vendors: {
            type: Array,
            required: true,
        }
    });

    const route = inject('route');
    const toast = inject('toast');

    /**
     * State
     */
    const form = reactive({
        client_companies: props.filters.client_companies ?? [],
        locations: props.filters.locations ?? [],
        vendors: props.filters.vendors ?? [],
        start_date: props.filters.start_date,
        end_date: props.filters.end_date,
        cost_filter: props.filters.cost_filter ?? 'All',
    });

    const saving = ref('passive');
    const state = ref('passive');
    const mounted = ref(false);
    const filtersInUse = ref(0);
    const clientCompaniesComboBoxQuery = ref('');
    const locationsComboBoxQuery = ref('');
    const vendorsComboBoxQuery = ref('');

    onMounted(() => {
        mounted.value = true;

        filtersInUse.value = getFiltersUsed.value;

        applyFormWatcher();
    });

    /**
     * Methods
     */
    function exportData() {
        saving.value = 'exporting';

        router.post(route('csv.compactor-monitors.reports.cumulative-portfolio-pickup-details'), {...form}, {
            onFinish: () => { saving.value = 'passive'; }
        });
    }

    function comboDisplayValue(option) {
        if (Array.isArray(option)) {
            if (option.length > 1) {
                return `${option.length} filters selected`;
            } else if (option.length === 1) {
                return '1 filter selected';
            } else {
                return 'No filters selected';
            }
        }

        return option ?? 'No filters selected';
    }

    function applyFormWatcher() {
        watch(
            () => form,
            throttle(function() {
                if(mounted.value) {
                    router.get(route("compactor-monitors.reports.cumulative-portfolio-pickup-details"), queryFilters.value, {
                        preserveState: true
                    });
                    filtersInUse.value = getFiltersUsed.value;
                }
            }, 150),
            {deep: true}
        );
    }

    function subNavTabs() {
        return [
            {name: 'Devices', href: route('compactor-monitors.index'), key: 'devices'},
            {name: 'Reports', href: '', key: 'reports', subnav: [
                    {name: 'Cumulative Monitor Impact', href: route('compactor-monitors.reports.cumulative-portfolio-impact-summaries'), key: 'cumulative-monitor-report'},
                    {name: 'Cumulative Pickup Details', href: route('compactor-monitors.reports.cumulative-portfolio-pickup-details'), key: 'cumulative-pickup-details'},
                ]},
        ]
    }

    function filterSelectedLocations() {
        if (form.client_companies.length === 0) {
            return;
        }

        let availableLocationIds = filteredLocationOptions.value.map(location => location.id);
        form.locations = form.locations.filter(location => availableLocationIds.includes(location));
    }

    /**
     * Computed
     */
    const filteredClientCompanyOptions = computed(() => {
        return clientCompaniesComboBoxQuery.value === ''
            ? props.clientCompanies
            : props.clientCompanies.filter((clientCompany) => {
                return clientCompany.name.toLowerCase().includes(clientCompaniesComboBoxQuery.value.toLowerCase());
            });
    });

    const sortedClientCompanyOptions = computed(() => {
        return filteredClientCompanyOptions.value.sort((a, b) => {
            let a_hash = (form.client_companies.includes(a.id) ? 0 : 1) + "_" + a.name + "_" + a.id;
            let b_hash = (form.client_companies.includes(b.id) ? 0 : 1) + "_" + b.name + "_" + b.id;

            return a_hash.localeCompare(b_hash);
        });
    });

    const filteredLocationOptions = computed(() => {
        let availableLocations = form.client_companies.length > 0
            ? props.locations.filter(location => form.client_companies.includes(location.client_company_id))
            : props.locations;

        return locationsComboBoxQuery.value === ''
            ? availableLocations
            : availableLocations.filter((location) => {
                return location.name.toLowerCase().includes(locationsComboBoxQuery.value.toLowerCase());
            });
    });

    const sortedLocationOptions = computed(() => {
        return filteredLocationOptions.value.sort((a, b) => {
            let a_hash = (form.locations.includes(a.id) ? 0 : 1) + "_" + a.name + "_" + a.id;
            let b_hash = (form.locations.includes(b.id) ? 0 : 1) + "_" + b.name + "_" + b.id;

            return a_hash.localeCompare(b_hash);
        });
    });

    const filteredVendorOptions = computed(() => {
        return vendorsComboBoxQuery.value === ''
            ? props.vendors
            : props.vendors.filter((vendor) => {
                return vendor.name.toLowerCase().includes(vendorsComboBoxQuery.value.toLowerCase());
            });
    });

    const sortedVendorOptions = computed(() => {
        return filteredVendorOptions.value.sort((a, b) => {
            let a_hash = (form.vendors.includes(a.id) ? 0 : 1) + "_" + a.name + "_" + a.id;
            let b_hash = (form.vendors.includes(b.id) ? 0 : 1) + "_" + b.name + "_" + b.id;

            return a_hash.localeCompare(b_hash);
        });
    });

    const queryFilters = computed(() => {
        let query = { ...form};

        return has_search_filters(query) ? query : {remember: 'forget'};
    });

    const getFiltersUsed = computed(() => {
        const formFilters = [
            form.client_companies.length > 0,
            form.locations.length > 0,
            form.start_date,
            form.end_date,
            form.vendors,
            (form.cost_filter && form.cost_filter !== 'All'),
        ];

        return formFilters.filter(ff => Array.isArray(ff) ? ff.length > 0 : !!ff).length;
    });

</script>